import {Component, HostBinding, Input} from '@angular/core';
import {TranscriptionState} from "scribe-poc-shared";

/** "Pill" display of a transcription's state */
@Component({
    selector: 'app-state-pill',
    imports: [],
    template: '{{text}}',
    styleUrl: './state-pill.component.scss'
})
export class StatePillComponent {
	/** Class to set for the component */
	@HostBinding("class")
	class:string;

	/** Text for the pill */
	text:string;

	/** The current state of the recording */
	private _state:TranscriptionState;

	/** The current state of the recording */
	get state() {
		return this._state;
	}

	/** The current state of the recording */
	@Input()
	set state(value:TranscriptionState) {
		this._state = value;

		switch(value) {
			case TranscriptionState.Recording:
				this.text = "Paused";
				this.class = "recording";
				break;
			case TranscriptionState.Transcription:
				this.text = "Processing";
				this.class = "processing";
				break;
			case TranscriptionState.Transcribed:
				this.text = "Complete";
				this.class = "complete";
				break;
			case TranscriptionState.Errored:
				this.text = "Error";
				this.class = "error";
				break;
			default:
				this.text = "Not started";
				this.class = "not-started";
				break;
		}
	}
}
