import {Component, Inject} from '@angular/core';
import {MatProgressSpinnerModule} from "@angular/material/progress-spinner";
import {MAT_DIALOG_DATA} from "@angular/material/dialog";
import {YesNoData} from "../yes-no-dialog/yes-no-dialog.component";

/**
 * Very simple loading dialog that shows a that the application is loading, or an operation is taking place
 */
@Component({
    selector: 'app-page-loader',
    imports: [
        MatProgressSpinnerModule
    ],
    templateUrl: './page-loader.component.html',
    styleUrl: './page-loader.component.scss'
})
export class PageLoaderComponent {
	constructor(@Inject(MAT_DIALOG_DATA) public data:LoaderData) {
	}

	/** The text to show for the loader, defaults to "loading..." */
	get text() {
		return this.data.text ?? "Loading...";
	}
}

/** Data to pass to the page loader dialog */
export interface LoaderData {
	/** The text to show for the loader, defaults to "loading..." */
	text?:string;
}
