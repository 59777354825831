<div class="back-link">
	<a class="back-button" mat-ripple (preventDoubleClick)="goToEncounter()">< &nbsp;Back to encounter</a>
</div>

<h1>Select note template</h1>
<hr/>

<!-- standard encounter note -->
<div class="template-link" [class.active]="this.selectedTemplate === '0'" (click)="selectTemplate('0')">Standard Encounter Note</div>

<!-- dynamo templates -->
@if (templates) {
	@for (template of templates; track template.id) {
		<div class="template-link" [class.active]="this.selectedTemplate === template.id" (click)="selectTemplate(template.id)">{{ template.name }}</div>
	}
}

<app-bottom-buttons>
	<button [disabled]="selectedTemplate == null" class="big-white-button" mat-ripple (preventDoubleClick)="applyTemplate()">
		Apply
	</button>
</app-bottom-buttons>
