import {Component} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router, RouterLink} from "@angular/router";
import {environment} from "../../../../environments/environment";
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {SnackbarService} from "../../../services/snackbar.service";
import {resetPassword} from 'aws-amplify/auth';
import {PageLoaderService} from "../../../services/page-loader.service";

/** Page for when the user forgot their password and wants to reset */
@Component({
	selector: 'app-reset',
	imports: [
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatIconModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		FormsModule,
		RouterLink
	],
	templateUrl: './forgot.component.html',
	styleUrl: '../shared-login.scss'
})
export class ForgotComponent {
	/** the form for the page */
	form = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
	});

	constructor(private router:Router,
	            private http:HttpClient,
	            private snackbar:SnackbarService,
	            private loader:PageLoaderService) {

	}

	/** Submit the forgot request */
	async submit() {
		if(this.form.valid) {
			try {
				this.loader.show();

				const email = this.form.value.email;
				await firstValueFrom(this.http.post(`${environment.apiUrl}/self/reset`, {email}));

				// initiate reset password flow
				const response = await resetPassword({
					username: email
				});

				//determine whether the user can move on
				if(response.nextStep.resetPasswordStep == 'CONFIRM_RESET_PASSWORD_WITH_CODE') {
					await this.router.navigate(['/login', 'reset', email]);
				} else {
					await this.router.navigate(['/login']);
				}

				this.snackbar.message("An email with further instructions was sent to your address");
			} catch(e) {
				this.snackbar.error("Failed to reset password");
				return;
			} finally {
				this.loader.close();
			}
		}
	}
}
