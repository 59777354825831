@switch (state) {
	@case (RecordingStates.Waiting) {
		<button class="big-blue-button" mat-ripple (click)="startMic()">
			Start recording
		</button>

		@if (isAdmin) {
			<input #fileInput hidden="hidden" type="file" onclick="this.value=null" (change)="fileInputChange($event)"
			       [accept]="mimesAccepted"/>

			<button class="big-white-button" mat-ripple (click)="fileInput.click()">
				<mat-icon>upload</mat-icon>
				Upload file
			</button>
		}
	}
	@case (RecordingStates.Recording) {
		<button class="big-pink-button" mat-ripple (preventDoubleClick)="stopMic()">
			<mat-icon>pause</mat-icon>
			<span>Pause Recording</span>
			<span class="duration">{{ recordingTime | date : 'mm:ss' }}</span>
		</button>
	}
	@case (RecordingStates.Done) {
		<button class="big-blue-button" mat-ripple (click)="startMic()">
			<mat-icon>resume</mat-icon>
			<span>Resume recording</span>
			<span class="duration">{{ recordingTime | date : 'mm:ss' }}</span>
		</button>

		<button class="big-white-button" mat-ripple (click)="selectTemplate()">
			End & select template
		</button>
	}
	@case (RecordingStates.SelectTemplate) {
		<!-- selecting teplate -->
	}
	@case (RecordingStates.Unsupported) {
		<div class="error">Unfortunately, it seems like your device doesn't support audio input.</div>
	}
	@case (RecordingStates.NoDevices) {
		<div class="error">No microphones found on this device.</div>
	}
	@case (RecordingStates.MicDeclined) {
		<div class="error">It looks like you declined mic access, please refresh the page and try again.</div>
	}
	@case (RecordingStates.Error) {
		<div class="error">It looks like there was an error, check log for details.</div>
	}

}
