<table mat-table [dataSource]="dataSource">
	<ng-container matColumnDef="participant">
		<th mat-header-cell *matHeaderCellDef>Participant</th>
		<td mat-cell *matCellDef="let row"> {{ convertName(row.participant) }}</td>
	</ng-container>

	<ng-container matColumnDef="content">
		<th mat-header-cell *matHeaderCellDef>Content</th>
		<td mat-cell *matCellDef="let row"> {{ row.content }}</td>
	</ng-container>

	<tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
	<tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

	<!-- Row shown when there is no matching data. -->
	<tr class="mat-row" *matNoDataRow>

	</tr>
</table>
