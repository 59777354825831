

<div class="title">
	<a class="logo" routerLink="./"></a>
	<h3>Scribe POC</h3>
</div>

@if (notLogin()) {
	<div class="buttons">
		<a mat-button routerLink="/list">List</a>
		<a mat-button routerLink="/record">Record</a>

		<button mat-button (click)="logout()">Logout</button>
	</div>
}

