import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatSelectModule} from "@angular/material/select";
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatInputModule} from "@angular/material/input";
import {HttpClient} from "@angular/common/http";
import {Mime, StartUploadRequest} from "scribe-poc-shared";
import {firstValueFrom} from "rxjs";
import {environment} from "../../environments/environment";
import {FileChangeEvent} from "@angular/compiler-cli/src/perform_watch";
import {RouterLink} from "@angular/router";

@Component({
	selector: 'app-record',
	standalone: true,
	imports: [
		MatButtonModule,
		MatIconModule,
		MatFormFieldModule,
		MatSelectModule,
		FormsModule,
		MatInputModule,
		ReactiveFormsModule,
		RouterLink
	],
	templateUrl: './record.component.html',
	styleUrl: './record.component.scss'
})
export class RecordComponent implements OnInit, OnDestroy {
	readonly RecordingStates = RecordingStates;

	recordingId = new FormControl('',
		[Validators.required, Validators.maxLength(128)]);

	devices:MediaDeviceInfo[];

	selectedDevice:MediaDeviceInfo;

	audioUrl:string;

	private _state:RecordingStates = RecordingStates.Initial;
	private recorder:MediaRecorder;
	private preferredMime:string;
	private recordedChunks:Blob[];
	private finalBlob:Blob;


	constructor(private changeDetectorRef:ChangeDetectorRef,
	            private http:HttpClient) {
	}

	async ngOnInit() {
		if(this.isSupported) {
			const stream = await this.getStream();
			if(stream) {
				this.closeStream(stream);

				this.devices = await RecordComponent.getDevices();
				if(this.devices.length > 0) {
					this.state = RecordingStates.Waiting;
					this.selectedDevice = this.devices[0];
				} else {
					this.state = RecordingStates.NoDevices;
				}
			}
		} else {
			this.state = RecordingStates.Unsupported;
		}
	}

	ngOnDestroy():void {
		if(this.recorder) {
			this.recorder.onstop = null;
			this.stopMic();
		}
	}

	async getStream(deviceId?:string) {
		try {
			const constraints:MediaStreamConstraints = deviceId ? {audio: {deviceId}} : {audio: true};
			return await navigator.mediaDevices.getUserMedia(constraints);
		} catch(e) {
			console.error(`getUserMedia error occurred: ${e}`);
			this.state = RecordingStates.MicDeclined;
		}

		return undefined;
	}

	closeStream(stream:MediaStream) {
		for(const track of stream.getTracks()) {
			track.stop();
		}
	}

	fileInputChange($event:Event) {
		const files = ($event?.target as HTMLInputElement)?.files;

		if(files instanceof FileList && files.length > 0 && files[0] instanceof Blob) {
			this.setFinalBlob(files[0]);
		} else {
			console.error("Failed to read files from file event");
		}
	}

	async startMic() {
		const stream = await this.getStream(this.selectedDevice.deviceId);
		if(stream) {
			this.recorder = new MediaRecorder(stream, {mimeType:this.preferredMime});
			this.recordedChunks = []

			this.recorder.ondataavailable = (e) => {
				this.recordedChunks.push(e.data);
			};

			this.recorder.onstop = () => this.onStop();
			this.recorder.onerror = (e) => {
				console.log("An error occurred while recording", e);
				this.state = RecordingStates.Error;
			};

			this.recorder.start();

			this.state = RecordingStates.Recording;
		}
	}

	pauseMic() {
		if(this.recorder.state == "paused") {
			this.recorder.resume();
		} else {
			this.recorder.pause();
		}
	}

	stopMic() {
		this.recorder.stop();
		this.closeStream(this.recorder.stream);
	}

	private onStop() {
		console.log("Audio recording stopped");

		this.setFinalBlob(new Blob(this.recordedChunks, {type: this.preferredMime}));
		this.recordedChunks = undefined;
	}

	private setFinalBlob(blob:Blob) {
		this.finalBlob = blob;

		this.audioUrl = URL.createObjectURL(this.finalBlob);

		this.state = RecordingStates.Done;

		this.changeDetectorRef.detectChanges();
	}

	async upload() {
		if(this.recordingId.valid) {
			const recordingId = this.recordingId.value;

			this.state = RecordingStates.Uploading;
			this.changeDetectorRef.detectChanges();

			const data:StartUploadRequest = {
				recordingId,
				mimeType: this.preferredMime
			};

			try {
				const signedUrl = await firstValueFrom(this.http.post<string>(`${environment.apiUrl}/start`, data));
				console.log("Upload approved, uploading...");

				await firstValueFrom(this.http.put(signedUrl, this.finalBlob));
				console.log("Uploaded");

				this.state = RecordingStates.Uploaded;
			}
			catch(e) {
				console.error("Failed to upload audio file", e);
				this.state = RecordingStates.Error;
			}
		}
	}

	private getFirstSupportedMime():boolean {
		for(const mime of Mime.supportedMimes) {
			if(MediaRecorder.isTypeSupported(mime)) {
				this.preferredMime = mime;
				return true;
			}
		}

		return false;
	}

	reset() {
		this.recordingId.reset();
		this.state = RecordingStates.Waiting;
	}

	private static async getDevices() {
		const ret:MediaDeviceInfo[] = [];

		const devices = await navigator.mediaDevices.enumerateDevices();
		for(const device of devices) {
			if(device.kind == "audioinput") {
				ret.push(device);
			}
		}

		return ret;
	}

	get state() {
		return this._state;
	}

	set state(value:RecordingStates) {
		this._state = value;
		this.changeDetectorRef.detectChanges();
	}

	get recorderState() {
		return this.recorder?.state;
	}

	private get isSupported():boolean {
		return navigator.mediaDevices &&
			typeof navigator.mediaDevices.getUserMedia == "function" &&
			this.getFirstSupportedMime();
	}

	get mimesAccepted():string {
		return Mime.supportedExtensions.map(value => `.${value}`).join(",");
	}
}

export enum RecordingStates {
	Initial,
	NoDevices,
	Unsupported,
	MicDeclined,
	Waiting,
	Error,
	Recording,
	Done,
	Uploading,
	Uploaded
}
