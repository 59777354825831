<mat-form-field appearance="outline">
	<mat-label>{{ label }}</mat-label>
	<input [(ngModel)]="value" [disabled]="disabled" matInput [type]="hidePassword ? 'password' : 'text'" required
	       [autocomplete]="autocomplete">

	<button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" type="button">
		<mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
	</button>

	@if (error) {
		<mat-error>{{ error }}</mat-error>
	}
</mat-form-field>
