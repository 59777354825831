import {Routes} from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {RecordComponent} from "./record/record.component";
import {SessionService} from "./services/session.service";
import {ListComponent} from "./list/list.component";
import {TranscriptionComponent} from "./transcription/transcription.component";

export const routes:Routes = [
	{
		path: "login",
		title: "Login",
		component: LoginComponent
	},
	{
		path: "record",
		title: "Record",
		component: RecordComponent,
		canActivate: [SessionService.canActivateLoggedIn]
	},
	{
		path: "list",
		title: "Recording List",
		component: ListComponent,
		canActivate: [SessionService.canActivateLoggedIn]
	},
	{
		path: "transcript/:id",
		title: "Transcript",
		component: TranscriptionComponent,
		canActivate: [SessionService.canActivateLoggedIn]
	},
	{
		path: '**',
		redirectTo: "list"
	}
];
