import {Component} from '@angular/core';
import {TranscriptSegment} from "scribe-poc-shared";
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import * as changeCase from "change-case";
import {BaseAppointmentComponent} from "../base-appointment.component";

/** Show the transcript of the conversation */
@Component({
    selector: 'app-transcript',
    imports: [
        MatTableModule
    ],
    templateUrl: './transcript.component.html',
    styleUrl: './transcript.component.scss'
})
export class TranscriptComponent extends BaseAppointmentComponent {
	/** The columns displayed on the page */
	readonly displayedColumns:string[] = ['participant', 'content'];

	/** The data source for the table */
	readonly dataSource:MatTableDataSource<TranscriptSegment> = new MatTableDataSource();

	override onNewAppointmentData() {
		this.dataSource.data = this.transcription;
	}

	/** Convert a participant's name into something more human-readable */
	convertName(participant:string) {
		const participantName = changeCase.capitalCase(participant);
		return participantName.replace(" 0", "");
	}
}
