import {Component} from '@angular/core';
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";
import {SessionService} from "../../../services/session.service";
import {environment} from "../../../../environments/environment";
import {PageLoaderService} from "../../../services/page-loader.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {Router, RouterLink} from "@angular/router";
import {PasswordFieldComponent} from "../../../components/password-field/password-field.component";
import {signIn, signOut} from "aws-amplify/auth";

/** Handle the login page */
@Component({
	selector: 'app-login',
	imports: [
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatIconModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		FormsModule,
		RouterLink,
		PasswordFieldComponent
	],
	templateUrl: './login.component.html',
	styleUrl: '../shared-login.scss'
})
export class LoginComponent {
	readonly environment = environment;

	/** Login form */
	form = new FormGroup({
		email: new FormControl('', [Validators.required, Validators.email]),
		password: new FormControl('', Validators.required)
	});

	constructor(private router:Router,
	            private sessionService:SessionService,
	            private loaderService:PageLoaderService,
	            private snackbar:SnackbarService) {

	}

	/** Submit the login request */
	async submit() {
		if(this.form.valid) {
			try {
				await signOut();

				this.loaderService.show("Logging in...");

				const response = await signIn({
					username: this.form.value.email,
					password: this.form.value.password
				});

				switch(response.nextStep.signInStep) {
					case 'DONE':
						await this.sessionService.redirectAfterLogin();
						break;
					case 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED':
						await this.router.navigate(['/login', 'change']);
						break;
					case "RESET_PASSWORD":
						await this.router.navigate(['/login', 'reset', this.form.value.email]);
						break;
					default:
						console.error(`Unable to log in, next sign in step, "${response.nextStep.signInStep}" not supported`);
						this.snackbar.error("Failed to login");
						break;
				}
			} catch(e) {
				console.warn("Failed to login", e);
				this.snackbar.error("Failed to login");
			} finally {
				this.loaderService.close();
			}
		}
	}
}
