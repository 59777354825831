import {Directive, EventEmitter, HostListener, Input, OnDestroy, Output} from '@angular/core';

/** Special click handler that prevents a second click from occurring in a certain amount of time */
@Directive({
	selector: '[preventDoubleClick]'
})
export class PreventDoubleClickDirective implements OnDestroy {
	/** Prevent another click to occur in the given amount of time */
	@Input() preventTime = 500;

	/** Special click handler that dispatches if not already clicked recently */
	@Output() preventDoubleClick = new EventEmitter<Event>();

	/** The timeout currently running (undefined if not running) */
	private timeout?:number;

	constructor() {
	}

	ngOnDestroy():void {
		//close the timeout if running
		window.clearTimeout(this.timeout);
		this.timeout = undefined;
	}

	@HostListener('click', ['$event'])
	clickEvent(event:Event) {
		//if running then ignore the event
		if(this.timeout) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			//otherwise start the timeout
			this.timeout = window.setTimeout(() => {
				this.timeout = undefined;
			}, this.preventTime);

			//emit the event
			this.preventDoubleClick.emit();
		}
	}
}
