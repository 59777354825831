import {AbstractControl, ValidationErrors, ValidatorFn} from "@angular/forms";
import {environment} from "../../environments/environment";


export abstract class PasswordValidators {
	/** Regex for matching upper case characters */
	static UpperRegex = /[A-Z]/g;

	/** Regex for matching lower case characters */
	static LowerRegex = /[a-z]/g;

	/** Regex for matching number characters */
	static NumberRegex = /\d/g;

	/** Regex for matching special characters (note: spaces only work in the middle of a password, not at the ends) */
	static SpecialRegex = /[\^$*.\[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]/g

	static policyValidator():ValidatorFn {
		return (control:AbstractControl):ValidationErrors | null => {
			//get the policy and trim the password
			const policy = environment.passwordPolicy;
			const value = <string>control?.value.trim() ?? "";

			//get each error
			const errors = {
				upper: this.getError(this.UpperRegex, value, policy.minUpper),
				lower: this.getError(this.LowerRegex, value, policy.minLower),
				number: this.getError(this.NumberRegex, value, policy.minNumber),
				special: this.getError(this.SpecialRegex, value, policy.minSpecial),
				length: policy.minLength > 0 && policy.minLength > control.value.length || undefined,
			};

			//only return if there are errors
			for(const error of Object.values(errors)) {
				if(error === true) {
					return errors;
				}
			}

			return null;
		};
	};

	/**
	 * Return an error value for {@link ValidationErrors}
	 * @param regex the regex to validate against
	 * @param value the value of the string
	 * @param policy the amount of the regex that needs to be found
	 * @return true if an error is found or null if not
	 */
	private static getError(regex:RegExp, value:string, policy:number):true | null {
		if(policy > 0 && policy > (value.match(regex)?.length ?? 0)) {
			return true;
		}

		return null;
	}
}
