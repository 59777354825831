import {Component, ViewChild} from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {TranscriptionState} from "scribe-poc-shared";
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import {RecordComponent} from "./record/record.component";
import {WaitingHeaderComponent} from "./waiting-header/waiting-header.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {AppointmentNav} from "./appointment-nav/appointment-nav.component";
import {BaseAppointmentComponent} from "./base-appointment.component";
import {BottomButtonsComponent} from "../../components/bottom-buttons/bottom-buttons.component";
import {VisibilityTimer} from "../../lib/visibility-timer";
import {environment} from "../../../environments/environment";
import {Subscription} from "rxjs";
import {AppointmentHeaderComponent} from "./appointment-header/appointment-header.component";
import {Utils} from "../../lib/utils";
import {Router} from "@angular/router";
import {AppointmentService} from "../../services/appointment.service";

/** Show information on an appointment and any recordings that need to be done or are done */
@Component({
	selector: 'app-appointment',
	imports: [
		MatButtonModule,
		MatIconModule,
		MatTooltipModule,
		WaitingHeaderComponent,
		RouterOutlet,
		AppointmentNav,
		RecordComponent,
		BottomButtonsComponent,
		AppointmentHeaderComponent
	],
	templateUrl: './appointment.component.html',
	styleUrl: './appointment.component.scss'
})
export class AppointmentComponent extends BaseAppointmentComponent {

	constructor(appointmentService:AppointmentService,
							private router:Router) {
			super(appointmentService);
}


	/** The recording component on the page (can be null if not active) */
	private _recordingComponent:RecordComponent;

	/** Subscription for update timer */
	private update:Subscription;

	override ngOnInit() {
		super.ngOnInit();

		//start update timer
		this.update = new VisibilityTimer(environment.cacheTimeout).subscribe(() => {
			if(!this.isInRecording) {
				this.appointmentService.reloadAppointment().then();
			}
		})
	}

	override ngOnDestroy() {
		super.ngOnDestroy();

		this.update?.unsubscribe();
	}

	/** Is the recording state visible? */
	get isInRecording():boolean {
		return this.transcriptionState == undefined || this.transcriptionState == TranscriptionState.Recording
	}

	/** The recording component on the page (can be null if not active) */
	@ViewChild(RecordComponent)
	set recordingComponent(value:RecordComponent) {
		this._recordingComponent = value;
	}

	/** The recording component on the page (can be null if not active) */
	get recordingComponent() {
		return this._recordingComponent;
	}
}
