import {ApplicationConfig, provideAppInitializer} from '@angular/core';
import {provideRouter} from '@angular/router';

import {routes} from './app.routes';
import {provideAnimationsAsync} from '@angular/platform-browser/animations/async';
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from "@angular/common/http";
import {SessionService} from "./services/session.service";
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from "@angular/material/form-field";
import {MAT_ICON_DEFAULT_OPTIONS} from "@angular/material/icon";

export const appConfig:ApplicationConfig = {
	providers: [
		provideRouter(routes),
		provideAnimationsAsync(),
		provideHttpClient(withInterceptorsFromDi()),

		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				subscriptSizing: 'dynamic',
				appearance: 'outline'
			}
		},
		{
			provide: MAT_ICON_DEFAULT_OPTIONS,
			useValue: {
				fontSet: 'material-symbols-outlined'
			}
		},

		//add session service and interceptors for handling logging in / out and maintaining session
		SessionService,
		{provide: HTTP_INTERCEPTORS, useExisting: SessionService, multi: true}
	]
};
