@if (isSearchOpen) {
	<form class="search" (submit)="isSearchOpen = false" (clickOutside)="isSearchOpen = false">
		<mat-form-field subscriptSizing="dynamic" appearance="outline">
			<button mat-icon-button matPrefix aria-label="Search">
				<mat-icon>search-bar</mat-icon>
			</button>
			<mat-label>Search by patient's name</mat-label>
			<input matInput [formControl]="searchControl">
			<button matSuffix mat-icon-button aria-label="Clear" [disabled]="!searchControl.value"
			        (click)="resetSearch()">
				<mat-icon>close</mat-icon>
			</button>
		</mat-form-field>

		<button mat-icon-button type="button" (click)="isSearchOpen = false">
			<mat-icon>search_off</mat-icon>
		</button>
	</form>
} @else {
	<form>
		<button mat-button class="today" (click)="setToday()" [class.active]="isToday" type="button">Today</button>

		<mat-form-field appearance="outline" class="date-search">
			<mat-label>Choose a date</mat-label>
			<input matInput [matDatepicker]="picker" [formControl]="dateControl" (click)="picker.open()">
			<mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
			<mat-datepicker #picker></mat-datepicker>

			@if (dateControl.invalid) {
				<mat-error>Invalid start date</mat-error>
			}
		</mat-form-field>

		<button mat-icon-button (click)="isSearchOpen = true" type="button" class="right">
			<mat-icon>search</mat-icon>
		</button>
	</form>
}

<div class="filter">
	<button mat-button (click)="showProviderFilter()">
		<mat-icon>filter_list</mat-icon>

		@if (isUnset) {
			All providers
		} @else {
			{{ providerName }}
		}
	</button>
</div>
