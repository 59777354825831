@if (!isRecording) {
	<a class="back-button" mat-ripple [routerLink]="back">
		<mat-icon>chevron_left</mat-icon>
		<div>{{ title }}</div>
	</a>
} @else {
	@if (isInitialRecordingMessage) {
		<div class="info">
			<div class="inner">
				<mat-icon>info</mat-icon>
				<span>Ask for patient consent before recording</span>
			</div>
		</div>
	} @else {
		<div class="info">
			<div class="inner">
				<mat-icon>notifications</mat-icon>
				<span>Do not lock screen while recording</span>
			</div>
		</div>
	}
}
