import {Inject, LOCALE_ID, Pipe, PipeTransform} from '@angular/core';
import {DatePipe, formatDate} from "@angular/common";
import {SessionService} from "../services/session.service";
import {DateTime, IANAZone} from "luxon";

/**
 * Like {@link DatePipe}, but it outputs the date in the organizations default time zone. If none found, defaults to
 * local timezone
 */
@Pipe({
	name: 'orgDate',
	standalone: true
})
export class OrgDatePipe implements PipeTransform {
	constructor(@Inject(LOCALE_ID) public locale:string,
	            private sessionService:SessionService) {
	}

	/**
	 * Transform the supplied date to a string that is geo-located in the organization's time zone
	 * @param value date, string, or epoch time to convert
	 * @param format the format of the date, refer to {@link DatePipe} for formats
	 */
	transform(value:Date | string | number, format:string):string {
		if(!(value instanceof Date)) {
			value = new Date(value);
		}

		const timeZoneName = this.sessionService.organization?.time_zone ?? DateTime.local().zoneName;
		const offset = IANAZone.create(timeZoneName).formatOffset(value.getTime(), "techie");
		return formatDate(value, format, this.locale, offset);
	}
}
