import {Component, NgZone} from '@angular/core';
import {MatButtonModule} from "@angular/material/button";
import {MatIconModule} from "@angular/material/icon";
import { HttpClient } from "@angular/common/http";
import {PutAudioRequest, RecordingInfo, TranscriptionState} from "scribe-poc-shared";
import {firstValueFrom, Subscription} from "rxjs";
import {environment} from "../../../../environments/environment";
import {PutAudioResponse} from "scribe-poc-shared/dist/util/put-audio-response";
import {PageLoaderService} from "../../../services/page-loader.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {Utils} from "../../../lib/utils";
import {RecordingService} from "../../../services/recording.service";
import {AppointmentService} from "../../../services/appointment.service";
import {MatRipple} from "@angular/material/core";
import {BaseAppointmentComponent} from "../base-appointment.component";
import {SessionService} from "../../../services/session.service";
import {DatePipe} from "@angular/common";
import {PreventDoubleClickDirective} from "../../../lib/prevent-double-click.directive";
import {Router} from "@angular/router";

/** Handles the recording system and the state of the recording page */
@Component({
    selector: 'app-record',
	imports: [
		MatButtonModule,
		MatIconModule,
		MatRipple,
		DatePipe,
		PreventDoubleClickDirective
	],
    templateUrl: './record.component.html',
    styleUrl: './record.component.scss'
})
export class RecordComponent extends BaseAppointmentComponent {
	readonly RecordingStates = RecordingStates;

	/** Is the user an admin? */
	isAdmin:boolean;

	/** A previous recording that has already been made */
	private previousRecording:RecordingInfo;

	/** The current state of the recording process */
	private _state:RecordingStates = RecordingStates.Initial;

	/** The final recording of the audio when done */
	private finalBlob:Blob;

	/** Subscription for when audio recording stops */
	private stopSubscription:Subscription;

	/** Subscription for when audio recording errors */
	private errorSubscription:Subscription;

	constructor(appointmentService:AppointmentService,
	            private sessionService:SessionService,
	            private ngZone:NgZone,
	            private recordingService:RecordingService,
	            private http:HttpClient,
	            private pageLoaderService:PageLoaderService,
	            private snackbar:SnackbarService,
						  private router:Router) {
		super(appointmentService);
	}

	override ngOnInit() {
		super.ngOnInit();

		if (this.state == RecordingStates.SelectTemplate) {
			this.state = RecordingStates.Done;
		}

		this.isAdmin = this.sessionService.isAdmin;
	}

	override async onNewAppointmentData() {
		this.previousRecording = this.recording;

		if(await this.recordingService.testSupport()) {
			if(this.recordingService.devices.length == 0) {
				this.state = RecordingStates.NoDevices;
			} else {
				this.resetToDefaultState();
			}
		} else {
			this.state = RecordingStates.Unsupported;
		}
	}

	/** Reset the recording process to the default (waiting or done) */
	private resetToDefaultState() {
		if(this.state != RecordingStates.Unsupported && this.state != RecordingStates.NoDevices) {
			this.state = this.previousRecording ? RecordingStates.Done : RecordingStates.Waiting;
		}
	}

	override ngOnDestroy():void {
		super.ngOnDestroy();

		this.recordingService.stopMic();
	}

	/** Called when the user uploads a file to the input element on the page */
	fileInputChange($event:Event) {
		const files = ($event?.target as HTMLInputElement)?.files;

		//set the final blob if a file was uploaded
		if(files instanceof FileList && files.length > 0 && files[0] instanceof Blob) {
			this.finalBlob = files[0];
			this.uploadAudioPart().then();
		} else {
			console.error("Failed to read files from file event");
			this.snackbar.error("Failed to read files");
		}
	}

	/** Start recording the user */
	async startMic() {
		if(await this.recordingService.startMic()) {
			this.state = RecordingStates.Recording;

			this.stopSubscription = this.recordingService.onStopEvent.subscribe(blob => this.onStop(blob));
			this.errorSubscription = this.recordingService.onErrorEvent.subscribe(() => this.onError());
		} else {
			this.state = RecordingStates.Error;
		}
	}

	private onError() {
		this.state = RecordingStates.Error;
		this.stopSubscription.unsubscribe();
		this.errorSubscription.unsubscribe();
	}

	/** Stop recording and close the stream */
	stopMic() {
		//stop recording, the final blob will be saved once the onstop event is called
		this.recordingService.stopMic();
		this.pageLoaderService.show("Uploading recording...");
	}

	/** Called when the mic officially stops recording */
	private async onStop(blob:Blob) {
		this.stopSubscription.unsubscribe();
		this.errorSubscription.unsubscribe();

		//set the final blob of the audio
		this.finalBlob = blob;

		const duration = this.recordingService.recordingTime / 1000;

		//only upload if a recording is long enough
		if(duration < environment.recordingDurationThreshold) {
			this.showErrorAndReset(`Recording was too short: ${duration}`, "Your recording was too short to upload.");
		} else {
			await this.uploadAudioPart(duration);
		}
	}

	/**
	 * Upload a new audio part to the server
	 * @param duration the final audio duration in seconds
	 */
	private async uploadAudioPart(duration:number = 0) {
		//don't allow an upload that is too small in byte size
		if(this.finalBlob.size < environment.recordingSizeThreshold) {
			this.showErrorAndReset(`File size was too small: ${this.finalBlob.size}`,
				"There was an error uploading your recording, it's too small.");
			return;
		}

		try {
			//set the state
			this.state = RecordingStates.Uploading;

			let finalDuration = duration;

			if(duration <= 0) {
				try {
					finalDuration = await Utils.getAudioBlobDuration(this.finalBlob);
				} catch(e) {
					console.warn("Failed to get upload audio duration", e);
				}
			}

			//create the request
			const data:PutAudioRequest = {
				appointmentId: this.appointment.id,
				mimeType: this.finalBlob?.type ?? this.recordingService.preferredMime,
				audioLength: finalDuration
			};

			//initiate the upload and get a signed url for the upload
			const response =
				await firstValueFrom(this.http.put<PutAudioResponse>(`${environment.apiUrl}/recording/add`, data));

			//put the audio file to the signed url
			await firstValueFrom(this.http.put(response.signedUploadUrl, this.finalBlob));

			//make sure we're running inside the Angular zone, as to pickup async changes
			this.ngZone.run(() => {
				this.previousRecording = response;

				//set the state
				this.state = RecordingStates.Done;

				this.appointmentService.addNewRecording(this.previousRecording);
			});
		} catch(e) {
			console.error("Failed to upload audio file", e);
			this.snackbar.error("Failed to upload the audio file");
			this.resetToDefaultState();
		} finally {
			this.pageLoaderService.close();
		}
	}

	/**
	 * Show an error to the user and reset the recording state
	 * @param internalMessage an internal message to log to the console
	 * @param externalMessage an external message to show to the user in the snackbar
	 */
	private showErrorAndReset(internalMessage:string, externalMessage:string) {
		console.error(internalMessage);
		this.snackbar.error(externalMessage)
		this.pageLoaderService.close();
		this.resetToDefaultState();
	}

	selectTemplate() {
		console.log("selecting template");
		this.state = RecordingStates.SelectTemplate;
		this.router.navigate(["/appointments", this.previousRecording.appointmentId, "template"]).then();
	}

	/** Get the current state */
	get state() {
		return this._state;
	}

	/** Set the current state */
	set state(value:RecordingStates) {
		this._state = value;
	}

	/** Return the total time of the recording in milliseconds */
	get recordingTime():number {
		const prevTime = (this.previousRecording?.audioLength ?? 0) * 1000;

		// get the current time, only if we're recording or uploading (we don't have the final audio length yet from
		// the server)
		const currentTime = this.state == RecordingStates.Recording || this.state == RecordingStates.Uploading ?
			this.recordingService.recordingTime : 0;

		//add the previous time to the current time
		return prevTime + currentTime;
	}

	/** Is the recording in a state where the appointment nav shouldn't be shown? */
	get isBlockingRecordingState() {
		return this.state == RecordingStates.Recording;
	}

	/** A list of file extensions accepted for file uploads */
	get mimesAccepted() {
		return RecordingService.mimesAccepted;
	}
}

/** Recording state of the page */
export enum RecordingStates {
	/** Waiting to determine if mic supported */
	Initial,

	/** No audio devices found */
	NoDevices,

	/** Recording not supported */
	Unsupported,

	/** The user declined mic access */
	MicDeclined,

	/** Waiting for the user to either record or upload a file */
	Waiting,

	/** Unknown error with recording */
	Error,

	/** The user is currently recording audio */
	Recording,

	/** The audio file is currently being uploaded */
	Uploading,

	/** Select the template */
	SelectTemplate,

	/** Done recording waiting to upload */
	Done
}
