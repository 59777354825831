import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, firstValueFrom} from "rxjs";
import {
	AppointmentResponse,
	hasAppointmentResponseChanged,
	MedicalCode,
	RecordingInfo,
	TranscriptionState
} from "scribe-poc-shared";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {Utils} from "../lib/utils";
import {ActivatedRouteSnapshot, CanActivateFn, Router} from "@angular/router";
import {AppointmentsService} from "./appointments.service";
import {SnackbarService} from "./snackbar.service";

/**
 * Service that handles the information of a single appointment and its scribed summary. Also handles appointment
 * route guarding.
 */
@Injectable({
	providedIn: 'root'
})
export class AppointmentService {
	/** Currently loaded appointment notification for a single appointment */
	private onNewAppointmentSubject:BehaviorSubject<AppointmentResponse> = new BehaviorSubject(null);

	/** Should the appointment nav bar be hidden? */
	hideNavbar:boolean = false;

	constructor(private router:Router,
	            private http:HttpClient,
	            private snackbar:SnackbarService,
	            private appointmentsService:AppointmentsService) {
	}

	/** Resolver to a single appointment from the route id */
	static canActivateAppointment:CanActivateFn = async(route) => {
		return inject(AppointmentService).canActivateAppointment(route);
	};

	/** Resolver to a single appointment from the route id */
	async canActivateAppointment(route:ActivatedRouteSnapshot) {
		const id = route.paramMap.get("id");

		//load the appointment and if not there, redirect
		if(!await this.getAppointment(id)) {
			return this.router.createUrlTree(["/"]);
		}

		//if a valid appointment then drop them in the appropriate root, if not provided
		if(route.children.length == 0) {
			if(this.isNoteRoute()) {
				return this.router.createUrlTree(["/appointments", id, "note"]);
			}

			return this.router.createUrlTree(["/appointments", id, "pre-visit"]);
		}

		return true;
	};

	/** Reload the current appointment if there is one already loaded */
	reloadAppointment() {
		if(this.current?.appointment.id) {
			return this.getAppointment(this.current.appointment.id);
		}

		return Promise.resolve(false);
	}

	/**
	 * Get an appointment from the server
	 * @param id the id of the appointment
	 * @returns true if the appointment was found, false if otherwise
	 */
	async getAppointment(id:string) {
		let response:AppointmentResponse;

		try {
			//get it from the server
			response = await firstValueFrom(this.http.get<AppointmentResponse>(`${environment.apiUrl}/list/${id}`));
		} catch(e) {
			console.warn(`Failed to load appointment ${id}`, e);
			this.snackbar.error(`Failed to load appointment ${id}`);
		}

		if(!response) {
			return false;
		}

		//normalize the birthdate
		response.patient.birthdate = Utils.convertBirthDate(response.patient.birthdate);

		//only dispatch if there was a large enough change in the response
		if(hasAppointmentResponseChanged(this.current, response)) {
			this.onNewAppointmentSubject.next(response);
		}

		return true;
	}

	/**
	 * Searches for a CPT or ICD-10 code in the codes returned by the server
	 *
	 * @param {string} code - The code to be searched for.
	 * @return the found code or undefined if no code found
	 */
	findCode(code:string):MedicalCode {

		const diagnosticCode = this.current?.codes?.diagnosisCodes?.find(c => c.code == code);
		if(diagnosticCode) {
			diagnosticCode.isDiagnosis = true;
			return diagnosticCode;
		}

		const procedureCode = this.current?.codes?.procedureCodes?.find(c => c.code == code);
		if(procedureCode) {
			procedureCode.isDiagnosis = false;
			return procedureCode;
		}

		return undefined;
	}

	/**
	 * Can the given route be activated?
	 * @param routeType the type of route
	 * @returns true if the route can be activated, or url tree to redirect to
	 */
	static canActivate(routeType:RouteType):CanActivateFn {
		return () => {
			const value = inject(AppointmentService).canActivate(routeType);
			return typeof value == "boolean" ? value : inject(Router).createUrlTree(value);
		}
	}

	/**
	 * Can the given route be activated?
	 * @param routeType the type of route
	 * @returns true if the route can be activated, or commands for an url tree to redirect to
	 */
	canActivate(routeType:RouteType):true | any[] {
		const appointmentId = this.current?.appointment.id;
		const root = ["/appointments", appointmentId];

		switch(routeType) {
			case "note":
				return this.isNoteRoute() ? true : root;
			case "code":
				return this.isCodeRoute() ? true : root;
			case "transcript":
				return this.isTranscriptRoute() ? true : root;
			case "pre-visit":
				return true;
			case "template":
				return true;
		}
	}

	/** Can the transcript page be shown? */
	isTranscriptRoute():boolean {
		return this.state == TranscriptionState.Transcribed &&
			this.current.transcript != null &&
			this.current.transcript.length > 0;
	}

	/** Can the not page be shown? */
	isNoteRoute():boolean {
		return this.current.postVisit != null;
	}

	/** Can the codes page be shown? */
	isCodeRoute():boolean {
		return this.state == TranscriptionState.Transcribed && this.current.codes != null;
	}

	/** Called when a new recording is made or updated */
	addNewRecording(recordingInfo:RecordingInfo):void {
		this.current.recording = recordingInfo;

		this.appointmentsService.addRecording(recordingInfo);
	}

	/** Get the state of the current recording if it exists */
	get state() {
		return this.current?.recording?.state;
	}

	/** Return the current appointment */
	get current() {
		return this.onNewAppointmentSubject.value;
	}

	/** Called when the appointment changes */
	get onNewAppointment() {
		return this.onNewAppointmentSubject.asObservable();
	}
}

/** The type of the route to check for activation */
type RouteType = "pre-visit" | "note" | "code" | "transcript" | "template";
