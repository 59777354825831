<mat-accordion>
	@for (section of summary; track section; let index = $index) {
		<mat-expansion-panel [expanded]="index == 0" [disabled]="section.segments.length == 0">
			<mat-expansion-panel-header>
				<mat-panel-title>{{ section.name }}</mat-panel-title>
			</mat-expansion-panel-header>

			<ul>
				@for (segment of section.segments; track segment) {
					<li>
						<span class="clickable" (click)="onSelected(segment)">{{segment.content}}</span>

						@if(segment.subItems) {
							<ul>
								@for (subSegment of segment.subItems; track subSegment) {
									<li>
										<span class="clickable" (click)="onSelected(subSegment)">{{subSegment.content}}</span>
									</li>
								}
							</ul>
						}
					</li>

				}
			</ul>

<!--			<div>-->
<!--				<ul>-->
<!--					@for (segment of section.segments; track segment) {-->
<!--						<li>{{ segment.content }}</li>-->
<!--					}-->
<!--				</ul>-->
<!--			</div>-->
		</mat-expansion-panel>
	}
</mat-accordion>
