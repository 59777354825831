<form [formGroup]="form" (ngSubmit)="submit()">
	<mat-form-field appearance="outline">
		<mat-label>Email</mat-label>
		<input matInput formControlName="email" required type="email" autocomplete="username">
	</mat-form-field>

	<app-password-field formControlName="password" autocomplete="current-password"></app-password-field>

	<div class="forgot">
		<a routerLink="forgot">Forgot password</a>
	</div>

	<div class="buttons">
		<button color="primary" mat-flat-button [disabled]="!form.valid" aria-label="Press to login">
			Login
		</button>
	</div>
</form>
