import { Component } from '@angular/core';

/** Series of buttons to appear at the bottom of a page */
@Component({
    selector: 'app-bottom-buttons',
    imports: [],
    templateUrl: './bottom-buttons.component.html',
    styleUrl: './bottom-buttons.component.scss'
})
export class BottomButtonsComponent {

}
