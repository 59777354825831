export abstract class Mime {
	private static PreferredMimes:PreferredMime[] = [
		{name: "audio/webm", ext: "webm"},
		{name: "audio/ogg", ext: "ogg"},
		{name: "audio/wav", ext: "wav"},
		{name: "audio/flac", ext: "flac"},
		{name: "audio/mpeg", ext: "mp3"},
		{name: "audio/mp4", ext: "mp4"},
	];

	private static SupportedMimesAndExtensions =
		new Map<string, string>(this.PreferredMimes.map(value => [value.name, value.ext]));

	static normalizeMime(mimeType:string) {
		let value = mimeType.trim().toLowerCase();

		const index = value.indexOf(";");
		if(index > 0) {
			value = value.substring(0, index);
		}

		return value;
	}

	static mimeToFileExt(mimeType:string) {
		const ext = this.SupportedMimesAndExtensions.get(mimeType);
		if(!ext) {
			throw new Error(`Mime type "${mimeType}" is not supported`);
		}

		return ext;
	}

	static isSupportedMime(mimeType:string):boolean {
		return this.SupportedMimesAndExtensions.has(mimeType);
	}

	static get supportedMimes():string[] {
		return this.PreferredMimes.map(value => value.name);
	}

	static get supportedExtensions():string[] {
		return this.PreferredMimes.map(value => value.ext);
	}
}

interface PreferredMime {
	name:string;
	ext:string;
}
