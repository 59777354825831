import {Component, OnInit} from '@angular/core';
import {RouterOutlet} from '@angular/router';
import {PageLoaderService} from "./services/page-loader.service";
import {HeaderComponent} from "./components/header/header.component";
import {MatIconRegistry} from "@angular/material/icon";

/** Main component of the app */
@Component({
	selector: 'app-root',
	imports: [RouterOutlet, HeaderComponent],
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
	constructor(private pageLoaderService:PageLoaderService,
	            private iconRegistry:MatIconRegistry) {
	}

	ngOnInit() {
		//startup and start listening for page loads
		this.pageLoaderService.startup();

		this.iconRegistry.setDefaultFontSetClass("material-symbols-outlined");
	}
}
