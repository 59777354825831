import {Injectable} from '@angular/core';
import {MatSnackBar} from "@angular/material/snack-bar";

/** Simple service that makes interaction with {@link MatSnackBar} easier */
@Injectable({
	providedIn: 'root'
})
export class SnackbarService {

	constructor(private snackbar:MatSnackBar) {
	}

	/**
	 * Show an error message to the user with an "ok" button
	 * @param message the message to show
	 */
	error(message:string) {
		this.snackbar.open(message, "Ok", {duration: 60000});
	}

	/**
	 * Show a message to the user in the snack bar for a short amount of time
	 * @param message the message to show
	 */
	message(message:string) {
		this.snackbar.open(message, undefined, {duration: 5000});
	}
}
