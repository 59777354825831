import {Component, EventEmitter, Input, Output} from '@angular/core';
import {SummarySection} from "scribe-poc-shared";
import {MatExpansionModule} from "@angular/material/expansion";
import * as changeCase from "change-case";

@Component({
	selector: 'app-summary',
	standalone: true,
	imports: [
		MatExpansionModule
	],
	templateUrl: './summary.component.html',
	styleUrl: './summary.component.scss'
})
export class SummaryComponent {
	private readonly translations:{ [key:string]:string } = {
		"CHIEF_COMPLAINT": "Brief description for the patient's reason for visiting clinician.",
		"HISTORY_OF_PRESENT_ILLNESS": "Notes that provide information on patient's illness, including reference to severity, onset, timing of symptoms, current treatments, and the affected areas.",
		"REVIEW_OF_SYSTEMS": "Patient-reported evaluation of symptoms across different body systems.",
		"PAST_MEDICAL_HISTORY": "Details a patient's previous medical conditions, surgeries, and treatments.",
		"ASSESSMENT": "Notes that provide information on clinician's assessment of patient's health.",
		"PLAN": "Notes that reference any medical treatments, lifestyle adjustments, and further appointments."
	};

	summary:Summary[];

	@Output()
	readonly evidenceClicked:EventEmitter<string[]> = new EventEmitter<string[]>();

	@Input("summary")
	set sections(value:SummarySection[]) {
		// this.name = changeCase.capitalCase(name);

		this.summary = [];

		for(const section of value) {
			const newSection:Summary = {
				name: changeCase.capitalCase(section.name),
				description: this.translations[section.name],
				segments: []
			};

			this.summary.push(newSection);

			let isInList = false;
			let currentSub:Segment;

			for(const segment of section.segments) {
				const split = segment.content.split("\n");

				for(const element of split) {
					const trimmed = element.trim();

					if(trimmed.length == 0) {
						continue;
					}

					if(trimmed.startsWith("-") && currentSub && section.name != "PAST_MEDICAL_HISTORY") {
						if(!isInList) {
							isInList = true;
							currentSub.subItems = [];
						}

						currentSub.subItems.push({
							content: SummaryComponent.normalizeContent(trimmed),
							evidence: segment.evidence
						});
					} else {
						if(isInList) {
							isInList = false;
						}

						currentSub = {
							content: SummaryComponent.normalizeContent(trimmed),
							evidence: segment.evidence
						};

						newSection.segments.push(currentSub);
					}
				}
			}
		}
	}

	onSelected(subSegment:Segment) {
		this.evidenceClicked.next(subSegment.evidence);
	}

	static normalizeContent(content:string) {
		return content.replace(/-(\s)+/g, "");
	}
}

interface Summary {
	name:string;
	description:string;
	segments:Segment[];
}

interface Segment {
	content:string;
	subItems?:Segment[]
	evidence:string[];
}
