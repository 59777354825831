/** Timer that can be paused and started to get a full time */
export class Stopwatch {
	/** Increments of time that has been timed */
	private increments:Increment[] = [];

	/** The current time increment being worked on */
	private currentIncrement:Increment;

	/** The interval for updates, this is used because Angular doesn't like constantly changing values. */
	private updateInterval:any;

	/** Get the total duration of the timer */
	private _duration:number = 0;

	/**
	 * Create the stop watch
	 * @param updateTime the interval for updates of the duration
	 */
	constructor(private updateTime:number = 500) {
	}

	/** Start the timer */
	start() {
		this.currentIncrement = {
			start: new Date().getTime()
		}

		this.updateInterval = setInterval(() => this.setDuration(), this.updateTime)
	}

	/** Stop the timer */
	stop() {
		if(this.currentIncrement) {
			this.currentIncrement.stop = new Date().getTime();
			this.increments.push(this.currentIncrement);
			this.currentIncrement = null;
			this.setDuration();
		}

		clearInterval(this.updateInterval);
	}

	/** Calculate the total duration */
	private setDuration() {
		let total = this.currentIncrement ? new Date().getTime() - this.currentIncrement.start : 0;
		for(const increment of this.increments) {
			total += increment.stop - increment.start;
		}

		this._duration = total;
	}

	/** Get the total duration of the timer */
	get duration() {
		return this._duration;
	}
}

/** Increments of time that has been timed */
interface Increment {
	/** Start time in epoch ms */
	start:number;

	/** Stop time in epoch ms */
	stop?:number;
}
