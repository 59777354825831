import {Component} from '@angular/core';
import {BaseAppointmentComponent} from "../base-appointment.component";
import {NoteCategory, PostVisitCategories, PreVisitCategories} from "../../../lib/note-category";
import {Utils} from "../../../lib/utils";
import {AiHpiBullet, AiHpiSummary} from "scribe-poc-shared";
import {NoteElementComponent} from "./note-element/note-element.component";
import {AppointmentService} from "../../../services/appointment.service";
import {ActivatedRoute} from "@angular/router";
import {Subscription} from "rxjs";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";

/** Shows a pre-visit not to the user (maybe adapted later for showing enhanced note too) */
@Component({
	selector: 'app-note',
	imports: [
		NoteElementComponent,
		MatIconModule,
		MatButtonModule,
		MatTooltipModule
	],
	templateUrl: './note.component.html',
	styleUrl: './note.component.scss'
})
export class NoteComponent extends BaseAppointmentComponent {
	/** Tabs to show at the top and the categories in order to display on the page */
	tabs:NoteCategory[];

	/** Is this a pre-visit or a post-visit note */
	isPreVisit:boolean;

	/** Currently loaded note data */
	noteData:AiHpiSummary;

	/** Look up a category by its name */
	private categoryLookup:Map<string, NoteDisplay>;

	/** Data listening subscription */
	private dataSubscription:Subscription;

	constructor(appointmentService:AppointmentService, private route:ActivatedRoute) {
		super(appointmentService);
	}

	override ngOnInit() {
		super.ngOnInit();

		//listen for a data change (handle switching between pre-visit and post-visit notes
		this.dataSubscription = this.route.data.subscribe((data) => {
			const isPreVisit = data["isPreVisit"] === true;

			//if there is a change, then update
			if(isPreVisit != this.isPreVisit) {
				this.isPreVisit = isPreVisit;

				//make sure data is set
				if(this.data) {
					this.onNewAppointmentData();
				}
			}
		});
	}

	override ngOnDestroy() {
		super.ngOnDestroy();

		this.dataSubscription?.unsubscribe();
	}

	override onNewAppointmentData() {
		//Wait for data to be initialized
		if(this.isPreVisit === undefined) {
			return;
		}

		//get the note's data
		this.noteData = this.isPreVisit ? this.preVisit : this.postVisit;

		//get the right categories
		const categories = this.isPreVisit ? PreVisitCategories : PostVisitCategories;

		this.categoryLookup = new Map();

		this.tabs = [];

		//set up a note
		if(this.noteData) {
			for(const category of categories) {
				//find the top level category in the ai summary
				const element = this.noteData[category.aiName ?? category.name];

				//add the category
				this.tabs.push(category);

				const display = new NoteDisplay(category.name);

				// only add the element if it exists
				if(element) {
					this.buildCategory(display, element, category);
				}

				this.categoryLookup.set(category.name, display);
			}
		}
	}

	/**
	 * Build a note category's display and it's children recursively
	 * @param display the display to build
	 * @param element the AI element that was generated
	 * @param category top level category we're in, this will be undefined for children
	 */
	private buildCategory(display:NoteDisplay, element:AiHpiBullet, category?:NoteCategory) {
		if(Array.isArray(element)) {
			if(category?.notBullets === true) {
				display.statement = element;
			} else {
				display.items = element;
			}
		} else if(typeof element == "object") {
			display.children = [];
			for(const [key, value] of Object.entries(element)) {
				const childDisplay = new NoteDisplay(key);

				display.children.push(childDisplay);

				this.buildCategory(childDisplay, value);
			}
		} else {
			display.statement = element as string;
		}

		//if a prefixed statement, prefix it and assume it's a string or list of strings
		if(category?.isPrefixedStatement && display.statement) {
			if(Array.isArray(display.statement)) {
				display.statement[0] = `${this.buildPrefix()} ${display.statement[0]}`
			} else {
				display.statement = `${this.buildPrefix()} ${display.statement}`;
			}
		}
	}

	/** Build the patient's prefix to show before prefixed statements */
	private buildPrefix() {
		return `${Utils.getMrMs(this.patient)} ${this.patient.last_name} is a ` +
			`${Utils.getAge(this.patient)} y/o ${this.patient.sex.toLowerCase()}`;
	}

	/** Get a display descriptor by its category name */
	getDisplay(name:string):NoteDisplay {
		return this.categoryLookup.get(name);
	}

	/** Is the note's display empty? */
	isEmpty(category:NoteDisplay) {
		return !category.statement &&
			(!category.items || category.items?.length == 0) &&
			(!category.children || category.children?.length == 0)
	}
}

/** Display of a note element/category on the note page */
export class NoteDisplay {
	/** A string statement to show */
	private _statement?:string | string[];

	/** Bulleted list items to show */
	private _items?:string[];

	/** Child displays to show under this one */
	children?:NoteDisplay[];

	/**
	 * Create a new display
	 * @param name Full name of the element
	 */
	constructor(public name:string) {
	}

	/** A string statement to show */
	set statement(value:string | string[]) {
		if(Array.isArray(value)) {
			this._statement = value.map(value => NoteDisplay.cleanStatement(value));
		} else {
			this._statement = NoteDisplay.cleanStatement(value);
		}
	}

	/** A string statement to show */
	get statement() {
		return this._statement;
	}

	/** Bulleted list items to show */
	set items(value:string[]) {
		this._items = value.map(value => NoteDisplay.cleanStatement(value));
	}

	/** Bulleted list items to show */
	get items() {
		return this._items;
	}

	/** Clean a string from AI to remove some bad characters */
	private static cleanStatement(value:string) {
		return value.trim().replace(/^-(\s)*/g, '');
	}
}
