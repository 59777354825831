import {Injectable} from '@angular/core';
import {Stopwatch} from "../lib/stopwatch";
import {BehaviorSubject, Subject} from "rxjs";
import {Mime} from "scribe-poc-shared";

/** Handles interaction with the user's microphone for recording audio */
@Injectable({
	providedIn: 'root'
})
export class RecordingService {
	/** The selected device the user wants to record with */
	selectedDevice:MediaDeviceInfo;

	/** The various recording devices enabled on this computer */
	private _devices:MediaDeviceInfo[];

	/** Duration of a recording being made */
	private recordingStopwatch:Stopwatch;

	/** The current recorder recording the user */
	private recorder:MediaRecorder;

	/** The preferred mime type of the audio that this browser wants to record in */
	private _preferredMime:string;

	/** The recorded chunks of audio */
	private recordedChunks:Blob[];

	/** The final recording of the audio when done */
	private finalBlob:Blob;

	/** Events issued when the loaded input devices change */
	private onDeviceChangesSubject = new BehaviorSubject<MediaDeviceInfo[]>([]);

	/** Events issued when there is an error recording */
	private onErrorSubject = new Subject<Event>();

	/** Events issued when the mic stops recording */
	private onStopSubject = new Subject<Blob>();

	/** Wake lock monitoring for mobile */
	private wakeLock:WakeLockSentinel;

	/** Attempt to determine support of a microphone, this will also get the initial microphone devices enabled */
	async testSupport() {
		//attempt to get the devices, by starting the audio system and closing it quick
		if(this.isSupported) {
			const stream = await this.getStream();
			if(stream) {
				this.closeStream(stream);

				this._devices = await RecordingService.getDevices();
				this.onDeviceChangesSubject.next(this._devices);
				if(this._devices.length > 0) {
					this.selectedDevice = this._devices[0];
				}

				return true;
			}
		}

		return false;
	}

	/**
	 * Get an audio stream from the microphone
	 * @param deviceId the specific device to use, otherwise it will be the default
	 */
	private async getStream(deviceId?:string) {
		try {
			const constraints:MediaStreamConstraints = deviceId ? {audio: {deviceId}} : {audio: true};
			return await navigator.mediaDevices.getUserMedia(constraints);
		} catch(e) {
			console.error(`getUserMedia error occurred: ${e}`);
		}

		return undefined;
	}

	/** Close an opened audio stream and stop recording */
	private closeStream(stream:MediaStream) {
		for(const track of stream.getTracks()) {
			track.stop();
		}
	}

	/** Start recording the user */
	async startMic() {
		//get a stream with the desired audio device and start recording
		const stream = await this.getStream(this.selectedDevice.deviceId);
		if(stream) {
			//start the recording and chunks
			this.recorder = new MediaRecorder(stream, {mimeType: this.preferredMime});
			this.recordedChunks = [];

			//record when new chunks are recorded
			this.recorder.ondataavailable = (e) => {
				this.recordedChunks.push(e.data);
			};

			//handle the stop event
			this.recorder.onstop = () => this.onStop();

			//handle an error
			this.recorder.onerror = (e) => {
				console.error("An error occurred while recording", e);
				this.onErrorSubject.next(e);
			};

			if(navigator.wakeLock) {
				try {
					this.wakeLock = await navigator.wakeLock.request("screen")
				} catch(e) {
					console.warn("Failed to grab the wake lock", e);
				}
			}

			//start recording
			this.recorder.start();

			//start timing
			this.recordingStopwatch = new Stopwatch();
			this.recordingStopwatch.start();
			return true;
		}

		return false;
	}

	/** Stop recording and close the stream */
	stopMic() {
		if(this.recorder) {
			//stop recording, the final blob will be saved once the onstop event is called
			this.recorder.stop();
			this.closeStream(this.recorder.stream);
			this.recorder = undefined;
		}

		//release the wake lock if we can
		this.wakeLock?.release().then(() => {
			this.wakeLock = null;
		});

		this.recordingStopwatch?.stop();
	}

	/** Called when the mic officially stops recording */
	private onStop() {
		//set the final blob of the audio
		this.finalBlob = new Blob(this.recordedChunks, {type: this.preferredMime});
		this.recordedChunks = undefined;

		this.onStopSubject.next(this.finalBlob);
	}

	/**
	 * Get all the recording devices that the browser has access to. This won't work until the user gives permission
	 * for mic access
	 */
	static async getDevices() {
		const ret:MediaDeviceInfo[] = [];

		const devices = await navigator.mediaDevices.enumerateDevices();
		for(const device of devices) {
			if(device.kind == "audioinput") {
				ret.push(device);
			}
		}

		return ret;
	}

	/** The various recording devices enabled on this device */
	get devices() {
		return this._devices;
	}

	/** A list of file extensions accepted for file uploads */
	static get mimesAccepted():string {
		return Mime.supportedExtensions.map(value => `.${value}`).join(",");
	}

	/**
	 * Query the browser and get the first supported mime type, from a list of supported types
	 * @see Mime.supportedMimes
	 */
	private getFirstSupportedMime():boolean {
		for(const mime of Mime.supportedMimes) {
			if(MediaRecorder.isTypeSupported(mime)) {
				this._preferredMime = mime;
				return true;
			}
		}

		return false;
	}

	/** Return the total time of the recording in milliseconds */
	get recordingTime():number {
		//prioritize the stop watch, then the final duration, then 0
		return this.recordingStopwatch?.duration ?? 0;
	}

	/** The preferred mime type of the audio that this browser wants to record in */
	get preferredMime():string {
		return this._preferredMime;
	}

	/** Events issued when there is an error recording */
	get onErrorEvent() {
		return this.onErrorSubject.asObservable();
	}

	/** Events issued when the mic stops recording */
	get onStopEvent() {
		return this.onStopSubject.asObservable();
	}

	/** Events issued when the loaded input devices change */
	get onDeviceChangesEvent() {
		return this.onDeviceChangesSubject.asObservable();
	}

	/** Is mic recording supported by the browser / device? */
	private get isSupported():boolean {
		return navigator.mediaDevices &&
			typeof navigator.mediaDevices.getUserMedia == "function" &&
			this.getFirstSupportedMime();
	}
}
