<div class="controls">
	<mat-form-field>
		<mat-label>Filter</mat-label>
		<input matInput (keyup)="applyFilter($event)" #input [disabled]="loading">
	</mat-form-field>

	<a mat-flat-button color="primary" routerLink="/record">
		<mat-icon>mic</mat-icon>
		Record
	</a>

	<button mat-flat-button color="warning" [disabled]="selection.isEmpty() || loading" (click)="onDelete()">
		<mat-icon>delete</mat-icon>
		Delete Selected
	</button>
</div>

<div class="table-container">
	<table mat-table [dataSource]="dataSource" matSort>
		<ng-container matColumnDef="selected">
			<th mat-header-cell *matHeaderCellDef>
				<mat-checkbox (change)="$event ? toggleAllRows() : null"
				              [checked]="selection.hasValue() && isAllSelected"
				              [indeterminate]="selection.hasValue() && !isAllSelected"
				              [disabled]="loading">
				</mat-checkbox>
			</th>
			<td mat-cell *matCellDef="let row">
				<mat-checkbox (click)="$event.stopPropagation()"
				              (change)="$event ? selection.toggle(row) : null"
				              [checked]="selection.isSelected(row)">
				</mat-checkbox>
			</td>
		</ng-container>

		<ng-container matColumnDef="created">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
			<td mat-cell *matCellDef="let row"> {{ row.created | date : 'short' }}</td>
		</ng-container>

		<ng-container matColumnDef="name">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
			<td mat-cell *matCellDef="let row"> {{ row.name }}</td>
		</ng-container>

		<!-- Progress Column -->
		<ng-container matColumnDef="userEmail">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
			<td mat-cell *matCellDef="let row"> {{ row.userEmail }}</td>
		</ng-container>

		<!-- Name Column -->
		<ng-container matColumnDef="state">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
			<td mat-cell *matCellDef="let row">
				@switch (row.state) {
					@case (TranscriptionState.Upload) {
						<span>Waiting for Upload</span>
					}
					@case (TranscriptionState.Transcribed) {
						<span>Done</span>
					}
					@case (TranscriptionState.Transcription) {
						<span>Waiting for Transcription</span>
					}
					@case (TranscriptionState.Errored) {
						<mat-error>Errored!</mat-error>
					}
				}
			</td>
		</ng-container>

		<ng-container matColumnDef="elapsed">
			<th mat-header-cell *matHeaderCellDef mat-sort-header>Elapsed Time (mins)</th>
			<td mat-cell *matCellDef="let row"> {{ getElapsedTime(row) }}</td>
		</ng-container>

		<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
		<tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goto(row)"></tr>

		<!-- Row shown when there is no matching data. -->
		<tr class="mat-row" *matNoDataRow>
			@if (loading) {
				<td class="mat-cell" colspan="4">Loading...</td>
			} @else {
				<td class="mat-cell" colspan="4">No data matching the filter "{{ input.value }}"</td>
			}
		</tr>
	</table>
</div>
