import {Component, OnInit} from '@angular/core';
import {MatDialogModule, MatDialogRef} from "@angular/material/dialog";
import {MatButtonModule} from "@angular/material/button";
import {AppointmentsService} from "../../../services/appointments.service";
import {AppointmentsFilterService} from "../../../services/appointments-filter.service";
import {MatListModule} from "@angular/material/list";
import {ReactiveFormsModule} from "@angular/forms";
import {SessionService} from "../../../services/session.service";
import {UserType} from "@allaihealth/serverless-common";

/** Show the filter dialog for providers */
@Component({
	selector: 'app-appointments-filter-dialog',
	imports: [
		MatDialogModule,
		MatButtonModule,
		MatListModule,
		ReactiveFormsModule
	],
	templateUrl: './appointments-filter-dialog.component.html',
	styleUrl: './appointments-filter-dialog.component.scss'
})
export class AppointmentsFilterDialogComponent implements OnInit {
	/** The value for an unset filter (show all providers) */
	readonly unset = AppointmentsFilterService.UnsetProvider;

	/** All the providers */
	options:ProviderRow[] = [];

	constructor(private sessionService:SessionService,
	            private appointmentsService:AppointmentsService,
	            private appointmentsFilterService:AppointmentsFilterService,
	            private dialogRef:MatDialogRef<AppointmentsFilterDialogComponent>) {
	}

	ngOnInit():void {
		//assemble the provider selection list
		for(const provider of this.appointmentsService.providers) {
			this.options.push({
				id: provider.id,
				fullName: `${provider.first_name} ${provider.last_name}`
			});
		}
	}

	/** Set the provider id to the current user */
	setMe() {
		this.onSelect(this.sessionService.allaiUser.id)
	}

	/** Change the selection of the provider filter */
	onSelect(id:string) {
		this.appointmentsFilterService.providerId = id;
		this.dialogRef.close();
	}

	/** Is the current user a provider? */
	get isProvider() {
		return this.sessionService.allaiUser.user_type == UserType.Provider;
	}

	/** Get the selected provider's id */
	get selected() {
		return this.appointmentsFilterService.providerId;
	}
}

/** Row in the selection list */
interface ProviderRow {
	/** ID of the provider */
	id:string;

	/** Full name of the provider */
	fullName:string;
}
