import {Component, OnInit} from '@angular/core';
import {BaseAppointmentComponent} from "../base-appointment.component";

import { HttpClient } from "@angular/common/http";
import {firstValueFrom, Subscription} from "rxjs";
import {environment} from "../../../../environments/environment";
import {MatIconModule} from "@angular/material/icon";
import {MatRippleModule} from "@angular/material/core";
import {SnackbarService} from "../../../services/snackbar.service";
import {AppointmentService} from "../../../services/appointment.service";
import {RecordingInfo, TranscriptionState} from "scribe-poc-shared";
import {TemplateInfo} from "scribe-poc-shared";
import {TemplatesService} from "../../../services/templates.service";
import {PageLoaderService} from "../../../services/page-loader.service";
import {PutAudioResponse} from "scribe-poc-shared/dist/util/put-audio-response";
import {PreventDoubleClickDirective} from "../../../lib/prevent-double-click.directive";
import {Router} from "@angular/router";
import {BottomButtonsComponent} from "../../../components/bottom-buttons/bottom-buttons.component";

/** Show the available note templates */
@Component({
    selector: 'app-template',
    imports: [
			MatIconModule,
			MatRippleModule,
			PreventDoubleClickDirective,
			BottomButtonsComponent
    ],
    templateUrl: './template.component.html',
    styleUrl: './template.component.scss'
})
export class TemplateComponent extends BaseAppointmentComponent {

	public templates: TemplateInfo[];
	selectedTemplate: string;

	/** A previous recording that has already been made */
	private previousRecording:RecordingInfo;

	constructor(appointmentService:AppointmentService,
						  private templatesService:TemplatesService,
							private snackbar:SnackbarService,
							private http:HttpClient,
	            private pageLoaderService:PageLoaderService,
						  private router:Router) {
				super(appointmentService);
		}

	override ngOnInit() {
		this.loadTemplates();
		this.selectedTemplate = null;
		super.ngOnInit();
	}

	selectTemplate(templateId:string) {
		this.selectedTemplate = templateId;
	}

	isActive(templateId:string) {
		return this.selectedTemplate == templateId;
	}

	public async loadTemplates() {
		this.templates = await this.templatesService.loadTemplates();
		console.log("templates", this.templates);
	}

	async applyTemplate() {

		this.previousRecording = this.recording;

		const promise = firstValueFrom(this.http.post<PutAudioResponse>(
			`${environment.apiUrl}/recording/end/${this.previousRecording.appointmentId}/template/${this.selectedTemplate}`, {}));

		this.pageLoaderService.showAndListen(promise, "Ending appointment...");

		await promise;

		this.previousRecording.state = TranscriptionState.Transcription;
		this.previousRecording.templateId = this.selectedTemplate;
		this.appointmentService.addNewRecording(this.previousRecording);
		this.router.navigate(["/appointments", this.previousRecording.appointmentId, "pre-visit"]).then();
	}

	goToEncounter() {
		this.router.navigate(["/appointments", this.recording.appointmentId, "pre-visit"]).then();
	}

}

