@if (links) {
	@for (link of links; track link.routerLink) {
		@if (link.isActive) {
			<a [routerLink]="link.routerLink"
			   class="button"
			   routerLinkActive="active"
			   [matBadge]="link.count" [matBadgeHidden]="link.count == 0">
				<span class="text">{{ link.text }}</span>
				<span class="short-text">{{ link.shortText ?? link.text }}</span>
			</a>
		} @else {
			<div class="button disabled">
				<span>{{ link.text }}</span>
			</div>
		}
	}
}
