import {Component, ElementRef, Input, ViewChild} from '@angular/core';
import {NoteDisplay} from "../note.component";
import {SnackbarService} from "../../../../services/snackbar.service";
import {convert} from "html-to-text";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {MatTooltipModule} from "@angular/material/tooltip";

/** Displays a note part on the note page of an appointment, this is also used recursively for children */
@Component({
	selector: 'app-note-element',
	templateUrl: './note-element.component.html',
	imports: [
		MatIconModule,
		MatButtonModule,
		MatTooltipModule
	],
	styleUrl: './note-element.component.scss'
})
export class NoteElementComponent {
	protected readonly Array = Array;

	/** The note display to show */
	@Input() display:NoteDisplay;

	@Input('is-child') isChild!:boolean;

	/** The content element where all the categories of the note are shown (for scrolling) */
	@ViewChild("content")
	private contentElement:ElementRef<HTMLElement>;

	constructor(private snackbarService:SnackbarService) {
	}

	/** Called when the user wants to copy the note to clipboard */
	async onCopy() {
		//get both html and text
		const html = this.contentElement?.nativeElement.innerHTML;

		if(html) {
			const text = convert(html, {
				selectors: [
					{selector: 'a', options: {ignoreHref: true}},
					{selector: 'img', format: 'skip'},
					{selector: 'ul', options: {itemPrefix: ' - ', trailingLineBreaks: 0}},
					{selector: 'div', options: {leadingLineBreaks: 0, trailingLineBreaks: 0}}
				]
			});

			//put both in clipboard item
			const clipboardItem = new ClipboardItem({
				"text/html": new Blob([html], {type: "text/html"}),
				"text/plain": new Blob([text], {type: "text/plain"})
			});

			try {
				//write to the clipboard
				await navigator.clipboard.write([clipboardItem]);
			} catch(error) {
				console.error("Failed to write to clipboard", error);
				this.snackbarService.error("Failed to copy to clipboard");
			}

			this.snackbarService.message(`Success! ${this.display.name} copied`);
		}
	}
}
