import {fromEvent, Observable, Subscriber, Subscription} from "rxjs";

/**
 * Special type of timer that only triggers when the page is visible
 * @see window.setTimeout
 * @see document.visibilityState
 */
export class VisibilityTimer extends Observable<void> {
	/** Subscriber that is currently listening */
	private subscriber:Subscriber<void>;

	/** Timer that is currently running */
	private timer:number;

	/** Document visibility event listening */
	private event:Subscription;

	/**
	 * Create a new timer
	 * @param period the period in which to execute in milliseconds
	 */
	constructor(private period:number) {
		super((subscriber) => {
			this.subscriber = subscriber;
			this.start();

			return () => this.stop();
		});
	}

	/** Start the timer */
	private start() {
		this.timer = window.setTimeout(() => {
			if(!this.tryTrigger()) {
				this.event = fromEvent(document, "visibilitychange").subscribe(() => this.tryTrigger());

			}
		}, this.period);
	}

	/** Try to see if the timer can trigger based on the visibility of the page */
	private tryTrigger() {
		if(document.visibilityState == "visible") {
			this.stop();
			this.start();
			this.subscriber.next();
			return true;
		}

		return false;
	}

	/** Stop the timer (called on unsubscribe) */
	private stop() {
		window.clearTimeout(this.timer);
		this.event?.unsubscribe();
	}
}
