import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource, MatTableModule} from "@angular/material/table";
import {Router} from "@angular/router";
import {DatePipe} from "@angular/common";
import {TranscriptionState} from 'scribe-poc-shared';
import {AppointmentsService} from "../../services/appointments.service";
import {StatePillComponent} from "../../components/state-pill/state-pill.component";
import {MatTooltipModule} from "@angular/material/tooltip";
import {OrgDatePipe} from "../../lib/org-date.pipe";
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {Subscription} from "rxjs";
import {AppointmentsControls} from "./appointments-controls/appointments-controls.component";
import {environment} from "../../../environments/environment";
import {VisibilityTimer} from "../../lib/visibility-timer";

/** The appointments list page */
@Component({
	selector: 'app-appointments',
	imports: [
		MatTableModule,
		MatIconModule,
		MatButtonModule,
		MatTooltipModule,
		DatePipe,
		StatePillComponent,
		OrgDatePipe,
		AppointmentsControls
	],
	templateUrl: './appointments.component.html',
	styleUrl: './appointments.component.scss'
})
export class AppointmentsComponent implements OnInit, OnDestroy {
	readonly TranscriptionState = TranscriptionState;

	/** Columns in the table */
	readonly displayedColumns:(keyof AppointmentRow)[] = ['appointmentTime', 'patientName', 'state'];

	/** Data source for the table */
	dataSource:MatTableDataSource<AppointmentRow>;

	/** Current search filter being applied */
	private _searchFilter:string;

	/** Table on the page showing the appointments */
	@ViewChild(MatTable)
	private table:MatTable<AppointmentRow>;

	/** Interval checking if the page has gone stale */
	private updateInterval:Subscription;

	/** Subscription for new appointments being loaded from filters */
	private newAppointmentsSub:Subscription;

	constructor(private appointmentsService:AppointmentsService,
	            private router:Router) {
	}

	ngOnInit() {
		this.newAppointmentsSub = this.appointmentsService.onNewAppointments.subscribe(() => {
			console.log("Refreshing Appointments");
			this.refresh();
		});

		//start timer to check for updates
		this.updateInterval = new VisibilityTimer(environment.cacheTimeout).subscribe(
			() => this.appointmentsService.resolveAppointments()
		);

		this.refresh();
	}

	private refresh() {

		//get the appointments loaded
		const appointments = this.appointmentsService.sortedAppointments;

		//convert the appointments into data for the list view
		const rows:AppointmentRow[] = appointments.flatMap(value => {
			const patient = this.appointmentsService.getPatient(value.id_patient);
			const recording = this.appointmentsService.getRecording(value.id);
			const isUpcomingAppointment = value.appointment_date > new Date();

			if(!patient) {
				return [];
			}

			return [{
				appointmentId: value.id,
				mrn: patient.mrn,
				patientName: `${patient.first_name} ${patient.last_name}`,
				dob: patient.birthdate,
				appointmentTime: value.appointment_date,
				appointmentType: value.ehr_appointment_type_name,
				state: recording?.state,
				isUpcomingAppointment: isUpcomingAppointment
			}];
		});

		//make sure to re-render table
		this.dataSource = new MatTableDataSource<AppointmentRow>(rows);
		this.table?.renderRows();
	}

	ngOnDestroy() {
		this.newAppointmentsSub?.unsubscribe();
		this.updateInterval?.unsubscribe();
	}

	/** Open an appointment that was clicked on */
	goto(row:AppointmentRow) {
		this.router.navigate(['/', 'appointments', row.appointmentId]).then();
	}

	/** Is the appointment a future appointment? */
	isCurrent(row:AppointmentRow) {
		return new Date() < new Date(row.appointmentTime)
	}

	/** Current search filter being applied */
	set searchFilter(value:string) {
		this._searchFilter = value;
		this.dataSource.filter = this._searchFilter.trim().toLowerCase();

		if(this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}
}

/** An appointment row to show in the list */
interface AppointmentRow {
	/** The id of the appointment */
	appointmentId:string;

	/** The patient's full name */
	patientName:string;

	/** The patient's MRN */
	mrn:string;

	/** The date of birth of the patient */
	dob:number | Date;

	/** The time the appointment starts */
	appointmentTime:number | Date;

	/** The state of the recording transcription */
	state:TranscriptionState;

	/** The type of the appointment from the ehr */
	appointmentType?:string;

	/** Whether or not this appointment has passed */
	isUpcomingAppointment?:boolean;
}
