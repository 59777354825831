import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {firstValueFrom} from "rxjs";
import {TemplateInfo, TemplatesResponse} from "scribe-poc-shared";
import {environment} from "../../environments/environment";

/** Service that loads note templates for the given user */
@Injectable({
	providedIn: 'root'
})
export class TemplatesService {

	constructor(private http:HttpClient) {
	}

	/** Resolver to get all templates for this user */
	async loadTemplates():Promise<TemplateInfo[]> {
		try {
				console.info("Loading template list for user");
				const templateResponse = await firstValueFrom(
					this.http.get<TemplateInfo[]>(`${environment.apiUrl}/templates`, {}));
				console.info("Template Response", templateResponse);
				return templateResponse;
		} catch (err) {
				console.warn("Unable to load templates", err);
		}
		return null;
	}

}
