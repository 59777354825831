<h1>Create a New Recording</h1>

<p>Use the options below to upload a new recording to the scribe:</p>

<mat-form-field>
	<mat-label>Recording Identifier</mat-label>
	<input matInput placeholder="Ex. My new recording / MRN" [formControl]="recordingId" autofocus>

	@if (recordingId.invalid) {
		<mat-error>Please enter a value</mat-error>
	}
</mat-form-field>

<div class="recording-section">

	@switch (state) {
		@case (RecordingStates.Waiting) {
			<mat-form-field>
				<mat-label>Input Device to Use</mat-label>
				<mat-select [(ngModel)]="selectedDevice">
					@for (device of devices; track device) {
						<mat-option [value]="device">{{ device.label }}</mat-option>
					}
				</mat-select>
			</mat-form-field>

			<div class="recording-controls">
				<button mat-flat-button color="primary" (click)="startMic()">
					<mat-icon>mic</mat-icon>
					Start Recording!
				</button>

				<input #fileInput hidden="hidden" type="file" onclick="this.value=null" (change)="fileInputChange($event)" [accept]="mimesAccepted"/>

				<button mat-flat-button (click)="fileInput.click()">
					<mat-icon>upload</mat-icon>
					Upload file
				</button>
			</div>
		}
		@case (RecordingStates.Recording) {
			<div class="recording-controls">
				@if (recorderState == "recording") {
					<button mat-stroked-button (click)="pauseMic()">
						<mat-icon>pause</mat-icon>
						Pause
					</button>
				} @else {
					<button mat-stroked-button (click)="pauseMic()">
						<mat-icon>play_arrow</mat-icon>
						Resume
					</button>
				}

				<button mat-flat-button color="primary" (click)="stopMic()">
					<mat-icon>stop</mat-icon>
					Stop
				</button>
			</div>
		}
		@case (RecordingStates.Done) {
			<p>Audio file was recorded</p>

			<h3>Playback:</h3>
			<p>
				<audio controls [src]="audioUrl"></audio>
			</p>

			<button mat-flat-button color="primary" (click)="upload()" [disabled]="recordingId.invalid">
				<mat-icon>upload</mat-icon>
				Upload
			</button>
		}
		@case (RecordingStates.Uploading) {
			<p>Uploading "{{ this.recordingId.value }}" now...</p>
		}
		@case (RecordingStates.Uploaded) {
			<p>Uploading "{{ this.recordingId.value }}" uploaded successfully!</p>

			<div class="recording-controls">
				<button mat-stroked-button (click)="reset()">Record Another?</button>

				<a mat-flat-button color="primary" routerLink="/list">Return to List</a>
			</div>
		}
		@case (RecordingStates.Unsupported) {
			<mat-error>Unfortunately, it doesn't seem like your browser doesn't support audio input.</mat-error>
		}
		@case (RecordingStates.NoDevices) {
			<mat-error>No microphones found on this device.</mat-error>
		}
		@case (RecordingStates.MicDeclined) {
			<mat-error>It looks like you declined mic access, please refresh the page and try again.</mat-error>
		}
		@case (RecordingStates.Error) {
			<mat-error>It looks like there was an error, check log for details.</mat-error>
		}
	}

</div>
