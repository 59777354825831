import {Routes} from '@angular/router';
import {AppointmentsComponent} from "./pages/appointments/appointments.component";
import {AppointmentsService} from "./services/appointments.service";
import {LoginComponent} from "./pages/login/login/login.component";
import {ListComponent} from "./pages/list/list.component";
import {AppointmentComponent} from "./pages/appointment/appointment.component";
import {SessionService} from "./services/session.service";
import {CodesComponent} from "./pages/appointment/codes/codes.component";
import {TranscriptComponent} from "./pages/appointment/transcript/transcript.component";
import {TemplateComponent} from "./pages/appointment/template/template.component";
import {AppointmentService} from "./services/appointment.service";
import {CodeComponent} from "./pages/appointment/codes/code/code.component";
import {NoteComponent} from "./pages/appointment/note/note.component";
import {ForgotComponent} from "./pages/login/forgot/forgot.component";
import {LoginIndexComponent} from "./pages/login/login-index.component";
import {ResetComponent} from "./pages/login/reset/reset.component";
import {ChangePasswordComponent} from "./pages/login/change-password/change-password.component";

export const routes:Routes = [
	{
		path: "login",
		component: LoginIndexComponent,
		children: [
			{
				path: "",
				title: "Login",
				component: LoginComponent
			},
			{
				path: "forgot",
				title: "Forgot Password",
				component: ForgotComponent
			},
			{
				path: "change",
				title: "Change Password",
				component: ChangePasswordComponent
			},
			{
				path: "reset",
				title: "Reset Password",
				component: ResetComponent
			},
			{
				path: "reset/:email",
				title: "Reset Password",
				component: ResetComponent
			}
		]
	},
	{
		path: "appointments",
		canActivate: [SessionService.canActivateLoggedIn],
		children: [
			{
				path: "",
				title: "Today's Appointments",
				component: AppointmentsComponent,
				resolve: {appointments: AppointmentsService.resolveAppointments},
			},
			{
				path: ":id",
				title: "Appointment",
				component: AppointmentComponent,
				canActivate: [AppointmentService.canActivateAppointment],
				data: {hasHeader: false},
				children: [
					{
						path: "pre-visit",
						component: NoteComponent,
						canActivate: [AppointmentService.canActivate("pre-visit")],
						data: {isPreVisit: true},
					},
					{
						path: "note",
						component: NoteComponent,
						canActivate: [AppointmentService.canActivate("note")],
						data: {isPreVisit: false},
					},
					{
						path: "codes",
						component: CodesComponent,
						canActivate: [AppointmentService.canActivate("code")]
					},
					{
						path: "codes/:code",
						component: CodeComponent,
						canActivate: [AppointmentService.canActivate("code")]
					},
					{
						path: "transcript",
						component: TranscriptComponent,
						canActivate: [AppointmentService.canActivate("transcript")]
					},
					{
						path: "template",
						component: TemplateComponent,
						canActivate: [AppointmentService.canActivate("template")]
					}
				]
			}
		]
	},
	{
		path: "list",
		title: "Recording List",
		component: ListComponent,
		canActivate: [SessionService.canActivateLoggedIn],
		resolve: {appointments: ListComponent.resolve}
	},
	{path: '**', redirectTo: 'appointments'}
];
