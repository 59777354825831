import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {UserType} from "@allaihealth/serverless-common";
import {SessionService} from "./session.service";
import {HttpParams} from "@angular/common/http";
import {Utils} from "../lib/utils";

/** Service that manages the appointments list filters to maintain their changes and reloading */
@Injectable({
	providedIn: 'root'
})
export class AppointmentsFilterService {
	/** The value for an unset filter (show all providers) */
	static readonly UnsetProvider = "unset";

	/** The provider who is being filtered */
	private _providerId:string;

	/** The date to show on the page (Default:today) */
	private _date:Date;

	/** Subject for filter changes */
	private onNewFilterSubject:Subject<void> = new Subject<void>();

	constructor(private sessionService:SessionService) {
		//clear on login
		this.sessionService.onLogin.subscribe(() => this.clear());
	}

	/** Dispatch an event on a change */
	private setChanged() {
		this.onNewFilterSubject.next();
	}

	/** Clear all saved filters and {@link localStorage} */
	clear() {
		this._providerId = undefined;
		this._date = undefined;
		localStorage.removeItem(this.providerStorageKey);
	}

	/** Reset the provider to the default value (depends on user type) */
	resetProvider() {
		this._providerId = localStorage.getItem(this.providerStorageKey);

		if(!this._providerId) {
			//providers are set to themselves, everyone else is unset
			this._providerId = this.sessionService.allaiUser.user_type == UserType.Provider ?
				this.sessionService.allaiUser.id : AppointmentsFilterService.UnsetProvider;
		}
	}

	/** The provider who is being filtered */
	get providerId():string {
		if(!this._providerId) {
			this.resetProvider();
		}

		return this._providerId;
	}

	/** The provider who is being filtered */
	set providerId(value:string) {
		if(this._providerId != value) {
			this._providerId = value;
			localStorage.setItem(this.providerStorageKey, this._providerId);
			this.setChanged();
		}
	}

	/** The date to show on the page (Default:today) */
	set date(value:Date) {
		if(this._date != value) {
			this._date = value;
			this.setChanged();
		}
	}

	/** The date to show on the page (Default:today) */
	get date():Date {
		if(!this._date) {
			this._date = new Date();
		}

		return this._date;
	}

	/** Dispatched for filter changes */
	get onNewFilter() {
		return this.onNewFilterSubject.asObservable();
	}

	/** Get the query parameters for a request to the backend server */
	get params():HttpParams {
		let params:HttpParams = new HttpParams();

		if(this.providerId != AppointmentsFilterService.UnsetProvider && this.providerId) {
			params = params.set('providerId', this.providerId);
		}

		const zone = this.sessionService.organization.time_zone;
		const start = Utils.setZonedStartEndDate(this.date, true, zone);
		const end = Utils.setZonedStartEndDate(this.date, false, zone);

		params = params.set("start", start.toMillis());
		params = params.set("end", end.toMillis());

		return params;
	}

	/** The storage key in {@link localStorage} to store the provider id for next time */
	private get providerStorageKey() {
		return `provider-filter`;
	}
}
