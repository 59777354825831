import {Component, forwardRef, Input} from '@angular/core';
import {MatFormField} from "@angular/material/form-field";
import {MatIconModule} from "@angular/material/icon";
import {ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatInputModule} from "@angular/material/input";

/** Component that adds a password text field to the page with a hide/show button */
@Component({
	selector: 'app-password-field',
	imports: [
		MatFormField,
		MatIconModule,
		MatInputModule,
		ReactiveFormsModule,
		MatButtonModule,
		FormsModule,
	],
	templateUrl: './password-field.component.html',
	styleUrl: './password-field.component.scss',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => PasswordFieldComponent),
			multi: true
		}
	]
})
export class PasswordFieldComponent implements ControlValueAccessor {
	/** Should the password be hidden or shown? */
	hidePassword:boolean = true;

	/** The label of the form field */
	@Input() label:string = "Password";

	/** An error message to show if validation fails */
	@Input() error?:string;

	/** A value for the autocomplete in the password field (undefined for nothing) */
	@Input() autocomplete?:AutoFill;

	/** Is the field disabled? */
	private _disabled:boolean = false;

	/** Value of the field */
	private _value:string;

	/** Called when the value changes */
	private onChanged:(value:string) => void;

	/** Called when the value is touched */
	private onTouched:() => void;

	writeValue(value:string):void {
		this._value = value;
	}

	registerOnChange(fn:any):void {
		this.onChanged = fn;
	}

	registerOnTouched(fn:any):void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled:boolean):void {
		this._disabled = isDisabled;
	}

	/** Get the value */
	get value():string {
		return this._value;
	}

	/** Set the value */
	set value(value:any) {
		this._value = value;
		this.onChanged?.(value);
		this.onTouched?.();
	}

	/** Is the field disabled? */
	get disabled():boolean {
		return this._disabled;
	}
}
