@if (requirement > 0) {
	<li [class.invalid]="invalid">
		@if(invalid) {
			<mat-icon inline="true">close</mat-icon>
		} @else {
			<mat-icon inline="true">check</mat-icon>
		}

		<ng-content></ng-content>
	</li>
}
