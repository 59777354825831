export interface RecordingInfo {
	id:string;
	name:string;
	userId:string;
	userEmail:string;
	mimeType:string;
	state:TranscriptionState;
	created:number;
	transcribed?:number;
}

export enum TranscriptionState {
	Upload = "upload",
	Transcription = "transcription",
	Transcribed = "transcribed",
	Errored = "errored"
}
