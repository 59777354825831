@if (!loading) {
	@if (transcription.recordingInfo.state != TranscriptionState.Transcribed) {
		<p>This transcription is still being processed, check back later.</p>
	} @else {
		<div class="info">
			<h1>Transcript and Summary for "{{ transcription.recordingInfo.name }}"</h1>
			<p>Elapsed transcription time: {{getElapsedTime()}} minutes</p>

			<audio controls [src]="transcription.audioFileUrl"></audio>
		</div>

		<div class="main">
			<mat-tab-group>
				<mat-tab label="Summary">
					<div class="tab">
						<app-summary [summary]="transcription.summarySections" (evidenceClicked)="onNewEvidence($event)"></app-summary>
					</div>
				</mat-tab>

				<mat-tab label="Transcript">
					<div class="tab">
						<app-transcript [transcript]="transcription.transcript" [evidence]="evidence"></app-transcript>
					</div>
				</mat-tab>
			</mat-tab-group>
		</div>
	}
} @else {
	<p>Loading...</p>
}
