<h2 mat-dialog-title>Select a provider</h2>
<mat-dialog-content>
	<div class="controls">
		@if (isProvider) {
			<button mat-button (click)="setMe()">My patients</button>
		}

		<button mat-button (click)="onSelect(unset)">All providers</button>
	</div>

	<mat-action-list>
		@for (option of options; track option) {
			<button mat-list-item
			        [class.active]="option.id == selected"
			        (click)="onSelect(option.id)">
				{{ option.fullName }}
			</button>
		}
	</mat-action-list>
</mat-dialog-content>

<mat-dialog-actions>
	<button mat-button mat-dialog-close cdkFocusInitial>Cancel</button>
</mat-dialog-actions>
