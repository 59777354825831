<div class="top">
@if (isChild === true) {
	<h3>{{ display.name }}</h3>
} @else {
	<h2>{{ display.name }}</h2>

	<button mat-icon-button class="copy-btn"
	        matTooltip="Copy to Clipboard"
	        aria-label="Copy to Clipboard"
	        (click)="onCopy()" color="primary">
		<mat-icon>content_copy</mat-icon>
	</button>
}
</div>

<div #content>
@if (display.statement) {
	@if (Array.isArray(display.statement)) {
		@for (part of display.statement; track part) {
			<p>{{ part }}</p>
		}
	} @else {
		<p>{{ display.statement }}</p>
	}
}

@if (display.items) {
	<ul>
		@for (item of display.items; track item) {
			<li>{{ item }}</li>
		}
	</ul>
}

@if (display.children) {
	@for (child of display.children; track child.name) {
		<app-note-element [display]="child" [is-child]="true"></app-note-element>
	}
}
</div>
