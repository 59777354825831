import {Component} from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {RouterOutlet} from "@angular/router";

/** Root of all the login pages */
@Component({
	selector: 'app-login-index',
	imports: [
		NgOptimizedImage,
		RouterOutlet
	],
	templateUrl: './login-index.component.html',
	styleUrl: './login-index.component.scss'
})
export class LoginIndexComponent {

}
