import {Component} from '@angular/core';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	ValidationErrors,
	ValidatorFn,
	Validators
} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {Router, RouterLink} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {PageLoaderService} from "../../../services/page-loader.service";
import {SnackbarService} from "../../../services/snackbar.service";
import {PasswordValidators} from "../../../lib/password-validators";
import {PasswordRequirementsComponent} from "../../../components/password-requirements/password-requirements.component";
import {PasswordFieldComponent} from "../../../components/password-field/password-field.component";
import {confirmSignIn} from 'aws-amplify/auth';

/** Change password page, for if the user is required to change a password by Cognito */
@Component({
	selector: 'app-change-password',
	imports: [
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatIconModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		FormsModule,
		PasswordRequirementsComponent,
		PasswordFieldComponent,
		RouterLink
	],
	templateUrl: './change-password.component.html',
	styleUrls: ['../shared-login.scss', './change-password.component.scss']
})
export class ChangePasswordComponent {
	/** The form for the change password page */
	form = new FormGroup({
		pass: new FormControl('', [PasswordValidators.policyValidator()]),
		repeat: new FormControl('', [Validators.required, this.repeatValidator()]),
	})

	constructor(private router:Router,
	            private loader:PageLoaderService,
	            private snackbar:SnackbarService) {
	}

	/** Validator to make sure the repeat password field matches the password */
	repeatValidator():ValidatorFn {
		return (control:AbstractControl):ValidationErrors | null => {
			return control.value == this.form?.value.pass ? null : {repeat: true};
		};
	}

	/** Submit the change password request */
	async submit() {
		if(this.form.valid) {
			this.loader.show();
			try {
				//make the request
				const response = await confirmSignIn({
					challengeResponse: this.form.value.pass
				});

				if(response.isSignedIn) {
					await this.router.navigate(['']);
				} else {
					this.snackbar.message("New password set, you can log in with your new password now");
					await this.router.navigate(['login']);
				}
			} catch(e) {
				this.snackbar.error("Failed to reset password");
			} finally {
				this.loader.close();
			}
		}
	}
}
