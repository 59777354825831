import {Component, HostBinding} from '@angular/core';
import {NavigationEnd, Router, RouterLink, RouterLinkActive} from "@angular/router";
import {BaseAppointmentComponent} from "../base-appointment.component";
import {MatBadgeModule} from "@angular/material/badge";
import {AppointmentService} from "../../../services/appointment.service";
import {Subscription} from "rxjs";
import {Utils} from "../../../lib/utils";

/** Navigation on the top of the appointment pages */
@Component({
	selector: 'app-appointment-nav',
	imports: [
		RouterLink,
		RouterLinkActive,
		MatBadgeModule
	],
	templateUrl: './appointment-nav.component.html',
	styleUrl: './appointment-nav.component.scss'
})
export class AppointmentNav extends BaseAppointmentComponent {
	/** Link descriptors in the navigation */
	links:NavLink[];

	/** Is the nav bar hidden */
	@HostBinding('class.hidden')
	isHidden:boolean = true;

	/** Listen to route changes */
	private routerSubscription:Subscription;

	constructor(appointmentService:AppointmentService, private router:Router) {
		super(appointmentService);

		this.routerSubscription = router.events.subscribe((e) => {
			if(e instanceof NavigationEnd && this.data) {
				this.update();
			}
		});
	}

	/** Update whether to show the bar */
	private update() {
		this.isHidden = (Utils.testRouteChildActive(this.router, ["/appointments", this.appointment.id, "codes"]) ||
										 Utils.testRouteChildActive(this.router, ["/appointments", this.appointment.id, "template"]));
	}

	override ngOnDestroy() {
		super.ngOnDestroy();

		this.routerSubscription?.unsubscribe();
	}

	protected override onNewAppointmentData():void | Promise<void> {
		this.links = [
			{
				text: 'Pre-visit',
				shortText: 'Pre',
				routerLink: 'pre-visit',
				isActive: true
			},
			{
				text: 'Note',
				routerLink: 'note',
				isActive: this.appointmentService.isNoteRoute()
			},
			{
				text: 'Codes',
				routerLink: 'codes',
				isActive: this.appointmentService.isCodeRoute(),
				count: this.numCodes
			},
			{
				text: 'Transcript',
				shortText: 'Txn',
				routerLink: 'transcript',
				isActive: this.appointmentService.isTranscriptRoute()
			}
		];

		this.update();
	}

	/** The total number of ICD-10 and CPT codes found */
	get numCodes() {
		const codes = this.data.codes;
		if(!codes) {
			return 0;
		}

		return (codes.diagnosisCodes?.length ?? 0) + (codes.procedureCodes?.length ?? 0);
	}
}

/** A navigation link description */
interface NavLink {
	/** The text of the link */
	text:string;

	/** If the screen is too small, show this text instead (defaults to {@link text} if not specified) */
	shortText?:string;

	/** The route of the link */
	routerLink:string;

	/** Any notification counters to show */
	count?:number;

	/** Is the link currently active or disabled? */
	isActive:boolean;
}
