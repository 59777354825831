import {DateTime} from "luxon";
import {Patient} from "@allaihealth/serverless-common";
import {IsActiveMatchOptions, Router, UrlTree} from "@angular/router";

/** Various utility functions for the application */
export abstract class Utils {
	/** Attempt to get the duration of an audio blob. This won't always work due to the way web audio works */
	static getAudioBlobDuration(blob:Blob) {
		return new Promise<number>((resolve, reject) => {
			const audio = new Audio(URL.createObjectURL(blob));

			audio.addEventListener("loadedmetadata", () => {
				if(isFinite(audio.duration) && audio.duration > 0) {
					resolve(audio.duration);
				}

				reject("Failed to get duration");
			}, {once: true});

			audio.addEventListener("error", event => {
				reject(event);
			});
		})
	}

	/**
	 * Convert a birthdate to the current time zone from UTC
	 * @param date the date of the birthdate in utc
	 */
	static convertBirthDate(date:string | number | Date) {
		let dateTime:DateTime;
		if(typeof date === "string") {
			dateTime = DateTime.fromISO(date, {zone: "utc"});
		} else if(typeof date === "number") {
			dateTime = DateTime.fromMillis(date, {zone: "utc"});
		} else {
			dateTime = DateTime.fromJSDate(date);
		}

		return new Date(dateTime.get("year"), dateTime.get("month") - 1, dateTime.get("day"));
	}

	/** Get whether this patient should be prefixed by "mr" or "ms", or "" if sex can't be determined */
	static getMrMs(patient:Patient):string {
		switch(patient.sex?.toLowerCase()) {
			case "male":
				return "Mr.";
			case "female":
				return "Ms.";
			default:
				return "";
		}
	}

	/** Get the age of a patient in years */
	static getAge(patient:Patient) {
		const duration = DateTime.now().diff(DateTime.fromJSDate(patient.birthdate), ["years"]);
		return Math.floor(duration.get("years"));
	}

	/** Get the age and sex of the patient for the header */
	static getAgeAndSex(patient:Patient) {
		let sex:string;
		switch(patient.sex?.toLowerCase()) {
			case "male":
				sex = "M";
				break;
			case "female":
				sex = "F";
				break;
			default:
				sex = " yo";
				break;
		}

		return `${this.getAge(patient)}${sex}`
	}

	/**
	 * Convert a date to the start of day in the given timezone. The underlying time will be adjusted so that the local
	 * time stays the same, but in the target zone (changes the timestamp, but not the time).
	 * @param date the date to convert
	 * @param isStartOfDay convert to the start of the day or the end of the day?
	 * @param zoneString the timezone string to convert to (e.g. "America/New_York")
	 * @see DateTime.setZone
	 */
	static setZonedStartEndDate(date:Date, isStartOfDay:boolean, zoneString:string) {
		let dateTime = DateTime.fromJSDate(date);

		if(zoneString == null) {
			console.error("Unable to determine org time zone, assuming local");
		} else {
			dateTime = dateTime.setZone(zoneString, {keepLocalTime: true});
		}

		return isStartOfDay ? dateTime.startOf("day") : dateTime.endOf("day");
	}

	/**
	 * Test a parent route to see if a child is active. For example if the passed in route is "/a/b" this will return
	 * true if the current route is "/a/b/c" or "/a/b/d", but not if the route is "/a/b".
	 * @param router angular router to use for testing
	 * @param route the route to check and see if a child is active
	 */
	static testRouteChildActive(router:Router, route:string | string[]) {
		let url:string | UrlTree = Array.isArray(route) ? router.createUrlTree(route) : route;

		const parent:IsActiveMatchOptions = {
			paths: 'exact',
			queryParams: 'ignored',
			fragment: 'ignored',
			matrixParams: 'ignored'
		};

		const child:IsActiveMatchOptions = {
			paths: 'subset',
			queryParams: 'ignored',
			fragment: 'ignored',
			matrixParams: 'ignored'
		};

		return !router.isActive(url, parent) && router.isActive(url, child);
	}
}
