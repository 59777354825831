import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import {MatButtonModule} from "@angular/material/button";
import {MatMenuModule} from "@angular/material/menu";
import {MatIconModule} from "@angular/material/icon";
import {MatDivider} from "@angular/material/divider";
import {SessionService} from "../../services/session.service";

/** Header of the site */
@Component({
	selector: 'app-header',
	imports: [
		MatButtonModule,
		RouterLink,
		MatMenuModule,
		MatIconModule,
		MatDivider
	],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss',
	host: {
		"[class.hidden]": "!showHeader"
	}
})
export class HeaderComponent implements OnInit {
	/** Should the header be shown? Sometimes it is hidden on different pages */
	showHeader = true;

	/** Should buttons be displayed on the header? */
	showButtons = true;

	constructor(private router:Router,
	            private sessionService:SessionService,
	            private activatedRoute:ActivatedRoute) {
		this.router.events.subscribe(event => {
			if(event instanceof NavigationEnd) {
				this.parseRoute();
			}
		});
	}

	ngOnInit() {
		this.parseRoute();
	}

	/** Parse the route to make sure if it has a route that should show the header */
	private parseRoute() {
		this.showHeader = true;
		this.showButtons = !this.router.url.startsWith("/login");

		let route = this.activatedRoute.snapshot;

		while (route.firstChild) {
			if(route.data?.["hasHeader"] === false) {
				this.showHeader = false;
				break;
			}

			route = route.firstChild
		}
	}

	/** Logout the user and return to the login page */
	async logout() {
		await this.sessionService.logout();
	}

	/** Is this an administrative user? */
	get isAdmin() {
		return this.sessionService.isAdmin;
	}
}
