export const environment = {
	/** Name of this environment */
	name: "dev",

	/** URL for the API REST server */
	apiUrl: "https://opdxqn3nbe.execute-api.us-east-1.amazonaws.com/prod",

	/** Login Cognito client ID */
	cognitoClientId: 'f4h9h1kqvv90ankstb3ido04d',

	/** Login Cognito pool ID */
	cognitoUserPoolId: 'us-east-1_By7X1BGHt',

	/** Time before a page loading spinner appears */
	pageLoaderTime: 300,

	/** The main portal url */
	mainUrl: "https://dev.allaihealth.dev",

	/** Time for api requests to stay cached */
	cacheTimeout: 180000,

	/** Reject recording durations less than this value (in seconds) for recording upload */
	recordingDurationThreshold: 5,

	/** Reject recording sizes less than this value (in bytes) for recording upload */
	recordingSizeThreshold: 1024,

	/** Password policy for the reset/change password form */
	passwordPolicy: {
		/** minimum length of password */
		minLength: 8,

		/** Minimum number of numbers in the password */
		minNumber: 1,

		/** Minimum number of special characters in the password */
		minSpecial: 1,

		/** Minimum number of upper case characters in the password */
		minUpper: 1,

		/** Minimum number of lower case characters in the password */
		minLower: 1
	}
};
