import {Component, Input} from '@angular/core';
import {RequirementComponent} from "./requirement/requirement.component";
import {ValidationErrors} from "@angular/forms";
import {environment} from "../../../environments/environment";

/**
 * Simple display to list a passwords validations against a password policy
 * @see environment.passwordPolicy
 * @see PasswordValidators
 */
@Component({
	selector: 'app-password-requirements',
	imports: [
		RequirementComponent
	],
	templateUrl: './password-requirements.component.html',
	styleUrl: './password-requirements.component.scss'
})
export class PasswordRequirementsComponent {
	/** Current password policy to comply to */
	policy = environment.passwordPolicy;

	/** Current errors of the password field */
	@Input() errors:ValidationErrors;
}
