<h1>Set new Password</h1>

<p>You need to change your password.</p>

<form [formGroup]="form" (ngSubmit)="submit()">
	<app-password-field formControlName="pass" label="New password" autocomplete="new-password"></app-password-field>

	<app-password-requirements [errors]="form.controls.pass.errors"></app-password-requirements>

	<app-password-field formControlName="repeat" label="Repeat new password" autocomplete="off"
	                    error="Passwords must match"></app-password-field>

	<div class="buttons">
		<button color="primary" mat-flat-button [disabled]="form.invalid">
			Change password
		</button>

		<a mat-stroked-button aria-label="Press to return to login" routerLink="/login">
			Cancel
		</a>
	</div>
</form>
