import {Component, Input} from '@angular/core';
import {RecordComponent} from "../record/record.component";
import {MatIconModule} from "@angular/material/icon";
import {MatRippleModule} from "@angular/material/core";
import {ActivatedRoute, NavigationEnd, Router, RouterLink} from "@angular/router";
import {BaseAppointmentComponent} from "../base-appointment.component";
import {TranscriptionState} from "scribe-poc-shared";
import {AppointmentService} from "../../../services/appointment.service";
import {Subscription} from "rxjs";

/**
 * Controls header of appointment page with back button and patient info. It will also change to show warnings on
 * recording start.
 */
@Component({
	selector: 'app-appointment-header',
	imports: [
		MatIconModule,
		MatRippleModule,
		RouterLink
	],
	templateUrl: './appointment-header.component.html',
	styleUrl: './appointment-header.component.scss'
})
export class AppointmentHeaderComponent extends BaseAppointmentComponent {
	/** Link to the recording component to get data from */
	@Input("recording")
	recordingComponent:RecordComponent;

	/** Title text of the back button */
	title:string;

	/** Back button url */
	back:string;

	/** Subscription to router changes */
	private titleSubscription:Subscription;

	constructor(appointmentService:AppointmentService, router:Router, private activatedRoute:ActivatedRoute) {
		super(appointmentService);

		this.titleSubscription = router.events.subscribe((event) => {
			if(event instanceof NavigationEnd && this.data) {
				this.detectTitle();
			}
		});
	}

	override ngOnInit() {
		super.ngOnInit();

		this.detectTitle();
	}

	override ngOnDestroy() {
		super.ngOnDestroy();

		this.titleSubscription?.unsubscribe();
	}

	/** Detect what the title should be of the current page */
	private detectTitle() {
		const code = this.activatedRoute.firstChild.snapshot.paramMap.get("code");
		if(code) {
			this.title = this.appointmentService.findCode(code).code;
			this.back = "codes";
		} else {
			this.title = `${this.patient?.first_name} ${this.patient?.last_name}`;
			this.back = "/appointments";
		}
	}

	/** Should the initial message of the indicator be shown */
	get isInitialRecordingMessage():boolean {
		return this.isRecording && this.recordingComponent.recordingTime < 5000;
	}

	/** Is the recording state visible? */
	get isInRecording():boolean {
		return this.transcriptionState == undefined || this.transcriptionState == TranscriptionState.Recording
	}

	/** Is this appointment currently being recorded into? */
	get isRecording():boolean {
		return this.isInRecording && this.recordingComponent?.isBlockingRecordingState;
	}
}
