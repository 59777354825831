<h1>Reset your Password</h1>

<form [formGroup]="form" (ngSubmit)="submit()">
	<mat-form-field appearance="outline">
		<mat-label>Email</mat-label>
		<input matInput formControlName="email" required type="email" autocomplete="username email">
	</mat-form-field>

	<div class="buttons">
		<button color="primary" mat-flat-button [disabled]="!form.valid" aria-label="Press to login">
			Send instructions
		</button>

		<a mat-stroked-button aria-label="Press to return to login" routerLink="../">
			Back
		</a>
	</div>
</form>
