import {Component, Input} from '@angular/core';
import {MatIcon} from "@angular/material/icon";

/** Part of the {@link PasswordRequirementsComponent} that shows a line item */
@Component({
	selector: 'app-requirement',
	imports: [
		MatIcon
	],
	templateUrl: './requirement.component.html',
	styleUrl: './requirement.component.scss'
})
export class RequirementComponent {
	/** The number of values needed */
	@Input() requirement:number

	/** Whether it is currently invalid */
	@Input() invalid:boolean;
}
