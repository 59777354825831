import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'transcriptParticipant',
	standalone: true
})
export class TranscriptParticipantPipe implements PipeTransform {
  transform(value: string): string {
    return value.indexOf("CLINICIAN") > -1 ? "Clinician" : "Patient";
  }
}
