import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatFormFieldModule} from "@angular/material/form-field";
import {provideNativeDateAdapter} from "@angular/material/core";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {MatDialog} from "@angular/material/dialog";
import {AppointmentsFilterDialogComponent} from "../appointments-filter-dialog/appointments-filter-dialog.component";
import {OverlayModule} from "@angular/cdk/overlay";
import {ClickOutsideDirective} from "../../../lib/click-outside.directive";
import {AppointmentsFilterService} from "../../../services/appointments-filter.service";
import {AppointmentsService} from "../../../services/appointments.service";

/** Controls for the appointments list page. This contains controlling search, provider, and date filters */
@Component({
	selector: 'app-appointments-filter',
	imports: [
		MatInputModule,
		MatButtonModule,
		MatDatepickerModule,
		MatFormFieldModule,
		MatIconModule,
		FormsModule,
		ReactiveFormsModule,
		OverlayModule,
		ClickOutsideDirective
	],
	providers: [provideNativeDateAdapter()],
	templateUrl: './appointments-controls.component.html',
	styleUrl: './appointments-controls.component.scss'
})
export class AppointmentsControls implements OnInit {
	/** The date control on the page */
	dateControl:FormControl<Date>;

	/** The search control on the page */
	searchControl = new FormControl<string>('');

	/** Is the search feature hidden or shown? */
	isSearchOpen:boolean = false;

	/** Dispatched when the search filter changes */
	@Output()
	readonly searchChange = new EventEmitter<string>();

	constructor(private appointmentsService:AppointmentsService,
	            private appointmentsFilterService:AppointmentsFilterService,
	            private matDialog:MatDialog) {
	}

	ngOnInit():void {
		this.dateControl = new FormControl<Date>(this.appointmentsFilterService.date, Validators.required);

		this.dateControl.valueChanges.subscribe((value) => this.appointmentsFilterService.date = value);

		this.searchControl.valueChanges.subscribe(value => this.searchChange.emit(value));
	}

	/** Set the date selection to today */
	setToday() {
		this.dateControl.setValue(new Date());
	}

	/** Open the provider filter dialog */
	showProviderFilter() {
		this.matDialog.open(AppointmentsFilterDialogComponent, {
			width: "768px"
		});
	}

	/** Reset the search filter */
	resetSearch() {
		this.searchControl.setValue('');
		this.searchChange.emit(this.searchControl.value);
	}

	/** Is the date in the date control today? */
	get isToday() {
		const today = new Date().setHours(0, 0, 0, 0);
		return this.dateControl.value.setHours(0, 0, 0, 0) == today;
	}

	/** Is the provider filter unset (Show all) */
	get isUnset() {
		return this.appointmentsFilterService.providerId == AppointmentsFilterService.UnsetProvider;
	}

	/** Return the provider's name if set */
	get providerName() {
		if(!this.isUnset) {
			const provider = this.appointmentsService.getProvider(this.appointmentsFilterService.providerId);
			return `${provider.first_name} ${provider.last_name}`;
		}

		return undefined;
	}
}
