@if (noteData) {
	@for (tab of tabs; track tab.name) {
		@let category = getDisplay(tab.name);

		@if (!isEmpty(category)) {
			<div [id]="category.name">
				<app-note-element [display]="category"></app-note-element>
			</div>
		}
	}
} @else if (isPreVisit) {
	<p>Questionnaire completion required to view pre-visit note.</p>
}


