import { Component } from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {environment} from "../../environments/environment";
import {Router, RouterLink} from "@angular/router";
import {SessionService} from "../services/session.service";
import {MatButtonModule} from "@angular/material/button";

@Component({
  selector: 'app-header',
  standalone: true,
	imports: [
		NgOptimizedImage,
		MatButtonModule,
		RouterLink
	],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent {

	protected readonly environment = environment;

	constructor(private router:Router, private sessionService:SessionService) {
	}

	notLogin() {
		return !this.router.url.startsWith("/login")
	}

	logout() {
		this.sessionService.logout();
		this.router.navigate(["login"]);
	}
}
