import {Component, OnDestroy, OnInit} from '@angular/core';
import {AppointmentService} from "../../../../services/appointment.service";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {MedicalCode} from "scribe-poc-shared";
import {MatButtonModule} from "@angular/material/button";
import {Subscription} from "rxjs";
import {TranscriptParticipantPipe} from '../../../../lib/transcript-participant-pipe';

/** Display for a CPT or ICD-10 code */
@Component({
	selector: 'app-code',
	imports: [
		MatButtonModule,
		RouterLink,
		TranscriptParticipantPipe
	],
	templateUrl: './code.component.html',
	styleUrl: './code.component.scss'
})
export class CodeComponent implements OnInit, OnDestroy {
	/** The code that the page is display info about */
	code:MedicalCode;

	/** Subscription to route changes */
	private subscription:Subscription;

	constructor(private appointmentService:AppointmentService,
	            private route:ActivatedRoute) {
	}

	ngOnInit():void {
		this.subscription = this.route.paramMap.subscribe(params => {
			this.code = this.appointmentService.findCode(params.get("code"));
		});

		this.appointmentService.hideNavbar = true;
	}

	ngOnDestroy():void {
		this.subscription?.unsubscribe();

		this.appointmentService.hideNavbar = false;
	}
}
