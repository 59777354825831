<h1>Set new Password</h1>

<p>Enter the verification code you received in your email.</p>

<div class="concierge-warning">
	If you didn't receive an email and should have, please email
	<a href="mailto:concierge@allaihealth.com">concierge&#64;allaihealth.com</a>
	for help.
</div>

<form [formGroup]="form" (ngSubmit)="submit()">
	@if (!isEmailSet) {
		<mat-form-field appearance="outline">
			<mat-label>Email</mat-label>
			<input matInput formControlName="email" required type="email" autocomplete="username email">
		</mat-form-field>
	}

	<mat-form-field appearance="outline">
		<mat-label>Verification code</mat-label>
		<input matInput formControlName="code" required autocomplete="off">
	</mat-form-field>

	<app-password-field formControlName="pass" label="New password" autocomplete="new-password"></app-password-field>

	<app-password-requirements [errors]="form.controls.pass.errors"></app-password-requirements>

	<app-password-field formControlName="repeat" label="Repeat new password" autocomplete="off"
	                    error="Passwords must match"></app-password-field>

	<div class="buttons">
		<button color="primary" mat-flat-button [disabled]="form.invalid" aria-label="Press to login">
			Set new password
		</button>

		<a mat-stroked-button aria-label="Press to return to login" routerLink="/login">
			Back
		</a>
	</div>
</form>
