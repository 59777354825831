import {Component} from '@angular/core';
import {MatIconModule} from "@angular/material/icon";
import {MatButtonModule} from "@angular/material/button";
import {RouterLink} from "@angular/router";
import {MatRippleModule} from "@angular/material/core";
import {BaseAppointmentComponent} from "../base-appointment.component";

/** Display a list of codes found in the transcript / DCI */
@Component({
	selector: 'app-codes',
	imports: [
		MatIconModule,
		MatButtonModule,
		RouterLink,
		MatRippleModule
	],
	templateUrl: './codes.component.html',
	styleUrl: './codes.component.scss'
})
export class CodesComponent extends BaseAppointmentComponent {
	/** All the procedure codes */
	get hasProcedureCodes() {
		return this.codes.procedureCodes?.length > 0;
	}

	/** All the diagnosis codes */
	get hasDiagnosisCodes() {
		return this.codes.diagnosisCodes?.length > 0;
	}
}
