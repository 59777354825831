import {AppointmentResponse, TranscriptionState} from "scribe-poc-shared";
import {AppointmentService} from "../../services/appointment.service";
import {Directive, OnDestroy, OnInit} from "@angular/core";
import {Subscription} from "rxjs";
import {Utils} from "../../lib/utils";


/**
 * Base component class for most components on an appointment page. Has some useful listening and retrieval functions
 * that all inheriting classes can use.
 */
@Directive()
export abstract class BaseAppointmentComponent implements OnInit, OnDestroy {
	/** The state of transcription of an audio recording */
	protected readonly TranscriptionState = TranscriptionState;

	/** Data from the backend on the appointment */
	protected data:AppointmentResponse;

	/** Subscription for appointment changes */
	private subscription:Subscription;

	constructor(protected appointmentService:AppointmentService) {
	}

	ngOnInit():void {
		//monitor for changes in the route
		this.subscription = this.appointmentService.onNewAppointment.subscribe(async() => {
			this.data = this.appointmentService.current;

			//notify inherited classes of a change
			const promise = this.onNewAppointmentData();
			if(promise instanceof Promise) {
				await promise;
			}
		});
	}

	/** A new appointment was loaded, this is likely due to a page change */
	protected onNewAppointmentData():void | Promise<void> {
	}

	ngOnDestroy() {
		this.subscription?.unsubscribe();
	}

	/** Get the current patient */
	get patient() {
		return this.data.patient;
	}

	/** Get the current recording */
	get recording() {
		return this.data.recording;
	}

	/** Get the current transcription */
	get transcription() {
		return this.data.transcript;
	}

	/** Get the current appointment */
	get appointment() {
		return this.data.appointment;
	}

	/** The codes for the visit */
	get codes() {
		return this.data.codes;
	}

	/** Get the current pre-visit note */
	get preVisit() {
		return this.data.preVisit;
	}

	/** Get the current post-visit combined note */
	get postVisit() {
		return this.data.postVisit;
	}

	/** Get the current recording state, or undefined if there is none */
	get transcriptionState():TranscriptionState {
		return this.data.recording?.state;
	}

	/** Get the age and sex of the patient for the header */
	get ageAndSex() {
		return Utils.getAgeAndSex(this.patient);
	}
}
