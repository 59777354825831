import {Component} from '@angular/core';
import {
	AbstractControl,
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	ValidationErrors,
	ValidatorFn,
	Validators
} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {ActivatedRoute, Router, RouterLink} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {environment} from "../../../../environments/environment";
import {PasswordFieldComponent} from "../../../components/password-field/password-field.component";
import {PageLoaderService} from "../../../services/page-loader.service";
import {confirmResetPassword} from 'aws-amplify/auth';
import {SnackbarService} from "../../../services/snackbar.service";
import {PasswordValidators} from "../../../lib/password-validators";
import {PasswordRequirementsComponent} from "../../../components/password-requirements/password-requirements.component";

/** Page for resetting a password after a forgot password or reset request */
@Component({
	selector: 'app-reset',
	imports: [
		MatButtonModule,
		MatInputModule,
		MatFormFieldModule,
		MatIconModule,
		MatProgressBarModule,
		ReactiveFormsModule,
		FormsModule,
		RouterLink,
		PasswordFieldComponent,
		PasswordRequirementsComponent
	],
	templateUrl: './reset.component.html',
	styleUrls: ['../shared-login.scss', './reset.component.scss']
})
export class ResetComponent {
	/** The form to fill out */
	form:FormGroup<ResetFormGroup>;

	/** The email of the user (potentially pre-populated) */
	private email:string;

	constructor(private router:Router,
	            private activatedRoute:ActivatedRoute,
	            private loader:PageLoaderService,
	            private snackbar:SnackbarService) {
		this.activatedRoute.params.subscribe(params => {
			//check if email set
			this.email = params["email"];

			//setup form group
			const group:ResetFormGroup = {
				code: new FormControl('', [Validators.required]),
				pass: new FormControl('', [PasswordValidators.policyValidator()]),
				repeat: new FormControl('', [Validators.required, this.repeatValidator()]),
			}

			//add email only if not set
			if(!this.email) {
				group.email = new FormControl('', [Validators.required, Validators.email]);
			}

			this.form = new FormGroup(group);
		});
	}

	/** Validator to make sure the repeat password field matches the password */
	repeatValidator():ValidatorFn {
		return (control:AbstractControl):ValidationErrors | null => {
			return control.value == this.form?.value.pass ? null : {repeat: true};
		};
	}

	/** Submit the reset password request */
	async submit() {
		if(this.form.valid) {
			this.loader.show();
			try {
				await confirmResetPassword({
					username: this.email ?? this.form.value.email,
					confirmationCode: this.form.value.code,
					newPassword: this.form.value.pass,
				});
			} catch(e) {
				this.snackbar.error("Failed to reset password");
			} finally {
				this.loader.close();
			}

			this.snackbar.message("New password set, you can log in with your new password now");
			await this.router.navigate(["/login"]);
		}
	}

	/** Was the email field already set? */
	get isEmailSet() {
		return typeof this.email === "string";
	}
}

/** Form group type for ease of use */
interface ResetFormGroup {
	email?:FormControl<string>;
	code:FormControl<string>;
	pass:FormControl<string>;
	repeat:FormControl<string>;
}
