/** A category in a note summary for the appointment combined note or pre-visit note */
export interface NoteCategory {
	/** Full name of the category */
	name:string;

	/** AI name for the category */
	aiName?:string;

	/** The short name of the category to use when space is limited */
	shortName?:string

	/** By default categories will be bullets, if this is set they will be forced to paragraphs */
	notBullets?:boolean;

	/**
	 * Is this category a prefixed statement? If yes then this category will not have children and will be prefixed by a
	 * message about the current user.
	 */
	isPrefixedStatement?:boolean
}

/** All categories in a pre-visit note in order of appearance */
export const PreVisitCategories:NoteCategory[] = [
	{
		name: "Chief Complaint",
		shortName: "CC",
		notBullets: true,
		isPrefixedStatement: true
	},
  {
		name: "Symptoms",
		shortName: "Sx"
	},
	{
		name: "Treatments",
		shortName: "PriorTx"
	},
	{
		name: "Imaging"
	},
	{
		name: "History",
		shortName: "Hx"
	}
];

/** All categories in a post-visit note in order of appearance */
export const PostVisitCategories:NoteCategory[] = [
	{
		name: "Chief Complaint",
		shortName: "CC",
		aiName: "CC",
		notBullets: true
	},
	{
		name: "History of Present Illness",
		shortName: "HPI",
		aiName: "HPI",
		notBullets: true
	}, {
		name: "Past Medical History",
		shortName: "PmHx",
		aiName: "PmHx"
	},
	{
		name: "Past Surgical History",
		shortName: "PsHx",
		aiName: "PsHx"
	},
	{
		name: "Family History",
		shortName: "FHx",
		aiName: "FHx"
	},
	{
		name: "Social History",
		shortName: "ScHx",
		aiName: "ScHx"
	},
	{
		name: "Medications",
		shortName: "Rx",
		aiName: "Medications"
	},
	{
		name: "Allergies",
		shortName: "Algy",
		aiName: "Allergies"
	},
	{
		name: "Physical Examination",
		shortName: "PE",
		aiName: "PE"
	},
	{
		name: "Assessment",
		shortName: "Asmt",
		aiName: "Assessment"
	},
	{
		name: "Plan",
		shortName: "Pl",
		aiName: "Plan"
	}
];
