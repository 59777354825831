import {Directive, ElementRef, EventEmitter, HostListener, OnInit, Output} from '@angular/core';

/** Dispatches an event when the user clicks outside the host element */
@Directive({
	selector: '[clickOutside]'
})
export class ClickOutsideDirective implements OnInit {
	/** Dispatches an event when the user clicks outside the host element */
	@Output() clickOutside = new EventEmitter<void>();

	/** Wait for a small amount of time before allowing clicks to register */
	private wait:boolean = false;

	constructor(private elementRef:ElementRef) {
	}

	ngOnInit():void {
		//Wait for a small amount of time before allowing clicks to register
		setTimeout(() => this.wait = true, 300);
	}

	/** Called when the user clicks anywhere on the page */
	@HostListener("document:click", ["$event.target"])
	onClick(target:HTMLElement) {
		if(this.wait && !this.elementRef.nativeElement.contains(target)) {
			this.clickOutside.emit();
		}
	}
}
