import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTable, MatTableDataSource, MatTableModule} from "@angular/material/table";
import {MatFormFieldModule} from "@angular/material/form-field";
import {RecordingInfo, TranscriptionState} from "scribe-poc-shared";
import {MatSort, MatSortModule} from "@angular/material/sort";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";
import {MatInputModule} from "@angular/material/input";
import {Router, RouterLink} from "@angular/router";
import {DatePipe} from "@angular/common";
import {MatCheckbox, MatCheckboxModule} from "@angular/material/checkbox";
import {SelectionModel} from "@angular/cdk/collections";
import {MatAnchor, MatButton} from "@angular/material/button";
import {MatIcon} from "@angular/material/icon";
import {firstValueFrom} from "rxjs";

@Component({
	selector: 'app-list',
	standalone: true,
	imports: [
		MatTableModule,
		MatFormFieldModule,
		MatSortModule,
		MatInputModule,
		RouterLink,
		DatePipe,
		MatCheckboxModule,
		MatAnchor,
		MatIcon,
		MatButton
	],
	templateUrl: './list.component.html',
	styleUrl: './list.component.scss'
})
export class ListComponent implements AfterViewInit, OnInit {
	readonly TranscriptionState = TranscriptionState;

	displayedColumns:string[] = ['selected', 'created', 'name', 'userEmail', 'state', 'elapsed'];
	dataSource:MatTableDataSource<RecordingInfo> = new MatTableDataSource<RecordingInfo>();
	loading:boolean = true;

	/** Items selected in the table */
	selection:SelectionModel<RecordingInfo> = new SelectionModel(true, []);

	@ViewChild(MatSort) sort:MatSort;
	@ViewChild(MatTable) table:MatTable<RecordingInfo>;

	constructor(private http:HttpClient, private router:Router) {
	}

	ngOnInit() {
		this.http.get<RecordingInfo[]>(`${environment.apiUrl}/list`).subscribe(value => {
			this.dataSource.data = value.sort((a, b) => {
				return b.created - a.created;
			});

			this.selection.clear();
			this.table?.renderRows();
			this.loading = false;
		})
	}

	ngAfterViewInit() {
		this.dataSource.sort = this.sort;
		this.table?.renderRows();
	}

	/** Selects all rows if they are not all selected; otherwise clear selection. */
	toggleAllRows() {
		if(this.isAllSelected) {
			this.selection.clear();
			return;
		}

		this.selection.select(...this.dataSource.data);
	}

	applyFilter(event:Event) {
		const filterValue = (event.target as HTMLInputElement).value;
		this.dataSource.filter = filterValue.trim().toLowerCase();

		if(this.dataSource.paginator) {
			this.dataSource.paginator.firstPage();
		}
	}

	goto(row:RecordingInfo) {
		this.router.navigate(['/', 'transcript', row.id]);
	}

	getElapsedTime(row:RecordingInfo) {
		return row.created && row.transcribed ? Math.round((row.transcribed - row.created) / 60000) : "";
	}

	async onDelete() {
		if(!this.selection.isEmpty()) {
			this.loading = true;

			await firstValueFrom(this.http.delete(`${environment.apiUrl}/delete`,
				{body:this.selection.selected.map(value => value.id)}));

			this.ngOnInit();
		}
	}

	/** Whether the number of selected elements matches the total number of rows. */
	get isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.dataSource.data.length;
		return numSelected === numRows;
	}
}
