import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {HttpClient} from "@angular/common/http";
import {RecordingInfo, SummarySection, Transcription, TranscriptionState, TranscriptSegment} from "scribe-poc-shared";
import {TranscriptComponent} from "./transcript/transcript.component";
import {SummaryComponent} from "./summary/summary.component";
import {MatTab, MatTabGroup, MatTabsModule} from "@angular/material/tabs";
import {environment} from "../../environments/environment";
import {MatCard, MatCardModule} from "@angular/material/card";

@Component({
	selector: 'app-transcription',
	standalone: true,
	imports: [
		TranscriptComponent,
		SummaryComponent,
		MatCardModule,
		MatTabsModule
	],
	templateUrl: './transcription.component.html',
	styleUrl: './transcription.component.scss'
})
export class TranscriptionComponent implements OnInit {
	readonly TranscriptionState = TranscriptionState;

	transcription:Transcription;
	loading:boolean = true;
	evidence:string[];

	@ViewChild(MatTabGroup)
	tabGroup:MatTabGroup;

	constructor(private route:ActivatedRoute, private http:HttpClient) {
	}

	ngOnInit():void {
		const id = this.route.snapshot.paramMap.get("id");

		this.http.get<Transcription>(`${environment.apiUrl}/list/${id}`).subscribe(value => {
			this.loading = false;
			this.transcription = value;
		});
	}

	onNewEvidence(evidence:string[]) {
		this.evidence = evidence;

		if(this.tabGroup) {
			this.tabGroup.selectedIndex = 1;
		}
	}

	getElapsedTime() {
		const recording = this.transcription.recordingInfo;
		return recording.created && recording.transcribed ?
			Math.round((recording.transcribed - recording.created) / 60000) : "n/a";
	}
}
