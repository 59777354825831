<ul>
	<app-requirement [invalid]="errors?.['length']" [requirement]="policy.minLength">
		{{ policy.minLength }} characters
	</app-requirement>

	<app-requirement [invalid]="errors?.['lower']" [requirement]="policy.minLower">
		{{ policy.minLower }} lowercase characters
	</app-requirement>

	<app-requirement [invalid]="errors?.['upper']" [requirement]="policy.minUpper">
		{{ policy.minUpper }} uppercase characters
	</app-requirement>

	<app-requirement [invalid]="errors?.['number']" [requirement]="policy.minNumber">
		{{ policy.minNumber }} numbers
	</app-requirement>

	<app-requirement [invalid]="errors?.['special']" [requirement]="policy.minSpecial">
		{{ policy.minSpecial }} special characters
	</app-requirement>
</ul>
