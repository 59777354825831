@if (hasProcedureCodes) {
	<div class="code-list-header">
		<h3>E/M</h3>
		<span>{{ codes.procedureCodes?.length ?? 0 }} code(s) detected</span>
	</div>


	<div class="code-list">
			@for (code of codes.procedureCodes; track code.code) {
				<a mat-ripple [routerLink]="[ code.code ]" class="code-link">
					<div class="code">{{ code.code }}</div>
					<div class="description">{{ code.description }}</div>
					<div class="next">
						<mat-icon>chevron_right</mat-icon>
					</div>
				</a>
			}
	</div>
}

@if (hasDiagnosisCodes) {
	<div class="code-list-header">
		<h3>ICD-10</h3>
		<span>{{ codes.diagnosisCodes?.length ?? 0 }} code(s) detected</span>
	</div>

	<div class="code-list">
		@for (code of codes.diagnosisCodes; track code.code) {
			<a mat-ripple [routerLink]="[ code.code ]" class="code-link">
				<div class="code">{{ code.code }}</div>
				<div class="description">{{ code.description }}</div>
				<div class="next">
					<mat-icon>chevron_right</mat-icon>
				</div>
			</a>
		}
	</div>
}
