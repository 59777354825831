"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.hasAppointmentResponseChanged = hasAppointmentResponseChanged;
/** Keys to check for existence, if these start existing or stop existing a change will be issued */
const checkForExistence = [
    'recording',
    'transcript',
    'preVisit',
    'postVisit',
    'codes'
];
/**
 * Detect if a major change has occurred between to {@link AppointmentResponse}s. A major change is adding or removing
 * of objects and not just simple text changes.
 * @param a first appointment response
 * @param b second to compare against
 * @returns true if a major change occurred
 */
function hasAppointmentResponseChanged(a, b) {
    if (a === b) {
        return false;
    }
    if (a?.appointment.id != b?.appointment.id) {
        return true;
    }
    if (a?.recording?.state != b?.recording?.state) {
        return true;
    }
    for (const key of checkForExistence) {
        if (typeof a[key] != typeof b[key]) {
            return true;
        }
    }
    return false;
}
