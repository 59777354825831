<app-appointments-filter (searchChange)="searchFilter = $event"></app-appointments-filter>

<div class="content">
	<table mat-table [dataSource]="dataSource">
		<ng-container matColumnDef="appointmentTime">
			<td mat-cell *matCellDef="let row"
			    [matTooltip]="row.appointmentTime | orgDate : 'long'">
				<div [class]="{'upcomingAppointment': row.isUpcomingAppointment}">{{ row.appointmentTime | orgDate : 'shortTime' }}</div>

				@if (row.appointmentType) {
					<div class="appointment-type sub">{{ row.appointmentType }}</div>
				}
			</td>
		</ng-container>

		<ng-container matColumnDef="patientName">
			<td mat-cell *matCellDef="let row">
				<div class="name" [class]="{'upcomingAppointment': row.isUpcomingAppointment}">{{ row.patientName }}</div>
				<div class="dob sub">{{ row.dob | date : 'MM/dd/yyyy' }}</div>
			</td>
		</ng-container>

		<ng-container matColumnDef="state">
			<td mat-cell *matCellDef="let row">
				<app-state-pill [state]="row.state"></app-state-pill>
				<div class="mrn sub">{{ row.mrn }}</div>
			</td>
		</ng-container>

		<tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="goto(row)"
		    [class.current]="isCurrent(row)"></tr>
	</table>

</div>
