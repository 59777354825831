<div class="code-content">
	<h3>
		@if (code.isDiagnosis) {
			ICD-10:
		} @else {
			E/M:
		} {{ code.code }}
	</h3>

	<p>{{ code.description }}</p>

	<h3>Supporting Documentation</h3>
	<h4>Evidence supporting the use of {{ code.code }}</h4>

	<!-- for ICD-10 codes-->
	@if (code.isDiagnosis) {

		@if (code.evidence) {
			@if (code.evidence.questionnaire && code.evidence.questionnaire.length > 0) {

				<div class="sub-container">

					<b>Pre-visit questionnaire evidence</b>
					<p></p>
					@for (question of code.evidence.questionnaire; track question.question) {
						Q: "{{ question.question }}"<br/>
						A: "{{ question.answer }}"
						<p></p>
					}

				</div>
			}


			@if (code.evidence.transcription && code.evidence.transcription.length > 0) {

				<div class="sub-container">
					<b>Encounter transcript evidence</b>
					<p></p>

					<a routerLink="../../transcript"><h4>Visit transcript ></h4></a>
					@for (transcript of code.evidence.transcription; track transcript.id) {
						@if (transcript.participant) {
							{{ transcript.participant | transcriptParticipant }}:
						}
						"{{ transcript.content }}"<br/>
						<p></p>
					}

				</div>
			}
		}

		<!-- for Procedure codes -->
	} @else {

		@if (code.mdm_components.diagnoses) {

			<div class="sub-container">

			<h3>Problems Addressed</h3>

			<!-- Data Review -->
			@if (code.mdm_components.diagnoses.evidence && code.mdm_components.diagnoses.evidence) {

				<b>Level:</b> {{ code.mdm_components.diagnoses.level }}<br/>
				<b>Interpretation:</b> {{ code.mdm_components.diagnoses.evidence.interpretation }}
				<p></p>

				<!-- Questionnaire -->
				@if (code.mdm_components.diagnoses.evidence.questionnaire && code.mdm_components.diagnoses.evidence.questionnaire.length > 0) {
					<b>Pre-visit questionnaire evidence</b><p></p>
					@for (question of code.mdm_components.diagnoses.evidence.questionnaire; track question.question) {
						Q: "{{ question.question }}"<br/>
						A: "{{ question.answer }}"
						<p></p>
					}
				}

				<!-- Transcript -->
				@if (code.mdm_components.diagnoses.evidence.transcription && code.mdm_components.diagnoses.evidence.transcription.length > 0) {
					<b>Encounter transcript evidence</b><p></p>
					@for (transcript of code.mdm_components.diagnoses.evidence.transcription; track transcript.id) {
						@if (transcript.participant) {
							{{ transcript.participant | transcriptParticipant }}:
						}
						"{{ transcript.content }}"<br/>
						<p></p>
					}
				}
			}
			</div>
		}

		@if (code.mdm_components) {

			@if (code.mdm_components.data_review) {

				<div class="sub-container">

					<h3>Data Review</h3>

					<!-- Data Review -->
					@if (code.mdm_components.data_review.evidence) {

						<b>Level:</b> {{ code.mdm_components.data_review.level }}<br/>
						<b>Interpretation:</b> {{ code.mdm_components.data_review.evidence.interpretation }}
						<p></p>

						<!-- Questionnaire -->
						@if (code.mdm_components.data_review.evidence.questionnaire && code.mdm_components.data_review.evidence.questionnaire.length > 0) {
							<b>Pre-visit questionnaire evidence</b><p></p>
							@for (question of code.mdm_components.data_review.evidence.questionnaire; track question.question) {
								Q: "{{ question.question }}"<br/>
								A: "{{ question.answer }}"
								<p></p>
							}
						}

						<!-- Transcript -->
						@if (code.mdm_components.data_review.evidence.transcription && code.mdm_components.data_review.evidence.transcription.length > 0) {
							<b>Encounter transcript evidence</b><p></p>
							@for (transcript of code.mdm_components.data_review.evidence.transcription; track transcript.id) {
								@if (transcript.participant) {
									{{ transcript.participant | transcriptParticipant }}:
								}
								"{{ transcript.content }}"<br/>
								<p></p>
							}
						}
					}
				</div>
			}

			<!-- Risk Management -->
			@if (code.mdm_components.risk_management) {

				<div class="sub-container">

					<h3>Risk Management</h3>

					<b>Level:</b> {{ code.mdm_components.risk_management?.level }}<br/>
					<b>Interpretation:</b> {{ code.mdm_components.risk_management.evidence.interpretation }}<br/>
					<p></p>

					<!-- Questionnaire -->
					@if (code.mdm_components.risk_management.evidence.questionnaire && code.mdm_components.risk_management.evidence.questionnaire.length > 0) {
						<b>Pre-visit questionnaire evidence</b><p></p>
						@for (question of code.mdm_components.risk_management.evidence.questionnaire; track question.question) {
							Q: "{{ question.question }}"<br/>
							A: "{{ question.answer }}"
							<p></p>
						}
					}

					<!-- Transcript -->
					@if (code.mdm_components.risk_management.evidence.transcription && code.mdm_components.risk_management.evidence.transcription.length > 0) {
						<b>Encounter transcript evidence</b><p></p>
						@for (transcript of code.mdm_components.risk_management.evidence.transcription; track transcript.id) {
							@if (transcript.participant) {
								{{ transcript.participant | transcriptParticipant }}:
							}
							"{{ transcript.content }}"<br/>
							<p></p>
						}
					}
				</div>
			}
		}
	}
</div>
