<app-appointment-header [recording]="recordingComponent"></app-appointment-header>

<app-appointment-nav></app-appointment-nav>

<div class="content">
	<router-outlet></router-outlet>
</div>

@if (isInRecording) {
	<app-bottom-buttons>
		<app-record></app-record>
	</app-bottom-buttons>
}

@if (transcriptionState == TranscriptionState.Transcription) {
	<app-waiting-header></app-waiting-header>
}
