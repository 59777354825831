import {inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {firstValueFrom, Subject} from "rxjs";
import {AppointmentsResponse, RecordingInfo, TemplateInfo, TemplatesResponse} from "scribe-poc-shared";
import {environment} from "../../environments/environment";
import {Appointment, Patient, User} from "@allaihealth/serverless-common";
import {SnackbarService} from "./snackbar.service";
import {SessionService} from "./session.service";
import {Utils} from "../lib/utils";
import {AppointmentsFilterService} from "./appointments-filter.service";
import {PageLoaderService} from "./page-loader.service";

/** Service that loads and caches all appointments in the appointment list page */
@Injectable({
	providedIn: 'root'
})
export class AppointmentsService {
	/** Loaded appointments by id */
	private appointments:Map<string, Appointment>;

	/** All appointments sorted by start time */
	private _sortedAppointments:Appointment[];

	/** loaded patients by their id */
	private patients:Map<string, Patient>;

	/** Loaded recordings by their id */
	private recordings:Map<string, RecordingInfo>;

	/** Loaded providers by their id */
	private _providers:Map<string, User>;

	/** Subject for new appointments list, usually from a filter change */
	private newAppointmentsSubject = new Subject<void>();

	constructor(private http:HttpClient,
	            private sessionService:SessionService,
	            private appointmentsFilterService:AppointmentsFilterService,
							private pageLoaderService:PageLoaderService,
	            private snackbar:SnackbarService) {
		//clear things out if the user logs out
		this.sessionService.onLogout.subscribe(() => {
			this.appointments = undefined;
			this._sortedAppointments = undefined
			this.patients = undefined;
			this.recordings = undefined;
			this._providers = undefined;
		});

		//listen to filter changes
		this.appointmentsFilterService.onNewFilter.subscribe(() => {
			this.pageLoaderService.showAndListen(this.resolveAppointments());
		});
	}

	/** Resolver to get all appointments for the list if not already loaded */
	static resolveAppointments():Promise<Appointment[]> {
		return inject(AppointmentsService).resolveAppointments();
	}

	/** Resolver to get all appointments for the list if not already loaded */
	async resolveAppointments():Promise<Appointment[]> {
		try {
			const params:HttpParams = this.appointmentsFilterService.params;

			//load from server
			const response = await firstValueFrom(
				this.http.get<AppointmentsResponse>(`${environment.apiUrl}/appointments`, {params}));

			this.appointments = new Map();

			//go through each appointment and add to map
			for(const appointment of response.appointments) {
				//set the date since dates can't be serialized in json
				appointment.appointment_date = new Date(appointment.appointment_date);

				this.appointments.set(appointment.id, appointment);
			}

			//sort the appointments
			this._sortedAppointments = response.appointments?.sort((a, b) =>
				a.appointment_date.getTime() - b.appointment_date.getTime());

			//add the patients
			this.patients = new Map(response.patients?.map(value => {
				value.birthdate = Utils.convertBirthDate(value.birthdate);
				return [value.id, value];
			}));

			//add the recordings
			this.recordings = new Map(response.recordings?.map(value => [value.appointmentId, value]));

			this._providers = new Map(response.providers?.map(value => [value.id, value]));

			//dispatch that there was a change
			this.newAppointmentsSubject.next();
		} catch(e) {
			//clear filter on error, just in case
			this.appointmentsFilterService.clear();

			console.error("Failed to load appointments from server", e);
			this.snackbar.error("Failed to download appointments");
		}

		return this.sortedAppointments;
	}

	/** Get a patient by id */
	getPatient(id:string) {
		return this.patients.get(id);
	}

	/** Get an appointment by id */
	getAppointment(id:string) {
		return this.appointments.get(id);
	}

	/** Get a recording by id */
	getRecording(id:string) {
		return this.recordings.get(id);
	}

	/**
	 * Add a new recording to the service
	 * @param recording the new recording to add
	 */
	addRecording(recording:RecordingInfo) {
		this.recordings?.set(recording.appointmentId, recording)
	}

	/** Get the provider with the given id */
	getProvider(providerId:string) {
		return this._providers.get(providerId);
	}

	/** Clear out loaded appointments and force a reload */
	clear() {
		this.appointments = undefined;
		this._sortedAppointments = undefined;
		this.patients = undefined;
		this.recordings = undefined;
	}

	/** Get the sorted appointments */
	get sortedAppointments() {
		return this._sortedAppointments;
	}

	/** Get a list of all providers */
	get providers():User[] {
		return Array.from(this._providers.values());
	}

	/** Dispatched for new appointments list, usually from a filter change */
	get onNewAppointments() {
		return this.newAppointmentsSubject.asObservable();
	}
}
